import client from "../client";

export const getUserContract = (user_id, user_type_id) => {
  const path = `/v1/users/${user_type_id}/${user_id}/contract`;
  return new Promise((resolve, reject) => {
    client.get(path).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const putUserContractSignature = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/contract`;
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getUserMati = (verification_type, user_type_id, user_id, person_type) => {
  const path = `/v1/users/${user_type_id}/${user_id}/mati-status?verification_type=${verification_type}&person_type=${person_type}`;
  return new Promise((resolve, reject) => {
    client.get(path).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
