import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { appService } from "../../services/app.service";
import SingContractUI from "./SingContract";
import useStyles from "./SingContract.styles";
import { useQuery } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getMati } from "../../redux/actions/contract.action";

export function SingContract(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();
  const query = useQuery();
  const [attempts, setAttempts] = useState(null);
  const [isIdentity, setIsIdentity] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const user_id = query.get("user_id");
  const user_type_id = query.get("user_type_id");
  const person_type = query.get("type");
  const titular_full_name = query.get("titular_full_name");
  const collateral_full_name = query.get("collateral_full_name");
    
  const metadata = `{"account_id":"${user_id}", "account_type_id":"${user_type_id}", "person_type":"${person_type}"}`;

  const { userMati } = useSelector(
    (state) => state.contractReducer
  );

  useEffect(() => {
    const button = document.getElementById("metamap-button");
    button.addEventListener("metamap:userFinishedSdk", ({ detail }) => {
      if(Object.keys(userMati).length !== 0 && userMati?.data && userMati.data && userMati.data.status === "APPROVED") {
        console.log('ENTRO A REVERIFICACIÓN');
        history(
          `/${user_type_id}/${user_id}/${titular_full_name}/${person_type}/${collateral_full_name}/review-documents?verification_type=REVERIFICATION`,
          { replace: true }
        );
      } else {
        console.log('ENTRO A VERIFICACIÓN');
        history(
          `/${user_type_id}/${user_id}/${titular_full_name}/${person_type}/${collateral_full_name}/review-documents?verification_type=VERIFICATION`,
          { replace: true }
        );
      }
    });
  });

  useEffect(() => {
    dispatch(getMati('VERIFICATION', user_type_id, user_id, person_type));
    setIsOpenModal(true);
  }, [])

  useEffect(() => {
    if(Object.keys(userMati).length !== 0) {
      if(userMati?.data && userMati.data && userMati.data.status === "APPROVED") {
        setAttempts(userMati.data.verifications_left);
        setIsIdentity(userMati.data.mati_identity_id)
      }
    }
  }, [userMati]);

  const viewerPDF = () => {
    window.open(
      `${appService.config.paths.api.basePath}/v1/users/${user_type_id}/${user_id}/contract`
    );
  };

  return (
    <SingContractUI
      classes={classes}
      viewerPDF={viewerPDF}
      metadata={metadata}
      attempts={attempts}
      isIdentity={isIdentity}
      isOpenModal={isOpenModal}
    />
  );
}

export default SingContract;
