import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks";

import ErrorDocumentsUI from "./ErrorDocuments";
import useStyles from "./ErrorDocuments.styles";
import { getMati } from "../../redux/actions/contract.action";

export function ErrorDocuments() {
  const history = useNavigate();
  const {
    user_id,
    user_type_id,
    person_type,
    titular_full_name,
    collateral_ful_name,
  } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const verificationType = query.get("verification_type");
  const [attempts, setAttempts] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const { userMati } = useSelector(
    (state) => state.contractReducer
  );

  useEffect(() => {
    dispatch(getMati(verificationType, user_type_id, user_id, person_type));
  }, [])

  useEffect(() => {
    if(Object.keys(userMati).length !== 0) {
      if(userMati?.data && userMati.data) {
        setAttempts(userMati.data.verifications_left)
      }
    }
  }, [userMati]);

  const reload = () => {
    history(
      `/send-contract-sign?user_type_id=${user_type_id}&user_id=${user_id}&titular_full_name=${titular_full_name}&type=${person_type}&collateral_full_name=${collateral_ful_name}`,
      { replace: true }
    );
  };

  return <ErrorDocumentsUI 
    classes={classes} 
    reload={reload} 
    attempts={attempts}
    isVisible={isVisible}
    setIsVisible={setIsVisible}
  />;
}

export default ErrorDocuments;
