import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Text from '../Text';
import useStyles from './Stepper.styles';
import images from '../../assets/images';

function Stepper({ activeStep, activeBars, isEvaluation }) {
  const classes = useStyles();

  const totalBars = 5;
  const steps = 3;

  return (
    <Box>
      <Box display="flex" alignItems="center" className={classes.stepperContainer}>
        <Box className={classes.stepBox}>
          <img
            src={isEvaluation ? images.evaluationIcon : images.registerIcon}
            className={classes.stepperIcon}
            alt="img-icon-step"
          />
        </Box>
        <Box>
          <Text className={classes.stepText}>
            Paso
            {' '}
            {activeStep}
            /
            {steps}
          </Text>
          <Text className={classes.stepInfoText}>{activeStep === 1 ? 'Registro' : 'Evaluación'}</Text>
        </Box>
      </Box>
      {!isEvaluation && (
      <Box display="flex" className={classes.stepperContainer}>
        {[...Array(totalBars)].map((_, index) => (
          <Box
            key={index}
            className={`${classes.stepIndicator} ${index < activeBars ? classes.activeStepIndicator : ''}`}
          />
        ))}
      </Box>
      )}
    </Box>
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  activeBars: PropTypes.number,
  isEvaluation: PropTypes.bool,
};

Stepper.defaultProps = {
  activeBars: 0,
  isEvaluation: false,
};

export default Stepper;
