import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

function Text({ children, ...others }) {
  return (
    <Typography {...others} style={{ fontFamily: "Poppins", lineHeight: 1.3 }}>
      {children}
    </Typography>
  );
}

export default Text;

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Text.defaultProps = {
  children: "",
};
