import * as ACTION from '../actionTypes/collateral';
import * as collateralService from '../../services/collateral.service';
import { INIT_VALIDATED_REVERSE_OTP } from '../actionTypes/collateral';

export const sendCollateralNIP =
  (user_type_id, user_id, to) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_SEND_NIP,
    });

    try {
      const nipData = await collateralService.sendCollateralNIP(
        user_type_id,
        user_id,
        to,
      );
      if (nipData) {
        dispatch({
          type: ACTION.RESPONSE_NIP_DATA,
          result: nipData.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_NIP_DATA,
        error,
      });
    }
  };

export const sendAvalReverseOtp =
  (accountTypeId, accountId, collateralId) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_SEND_NIP,
    });
    let ipAddress = '127.0.0.1';
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    ipAddress = ipData.ip;

    const payload = {
      account_type_id: accountTypeId,
      account_id: accountId,
      collateral_id: collateralId,
      ip_address: ipAddress,
    };
    try {
      const nipData = await collateralService.sendAvalReverseOtp(payload);
      if (nipData) {
        dispatch({
          type: ACTION.RESPONSE_REVERSE_OTP_DATA,
          result: nipData.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_NIP_DATA,
        error,
      });
    }
  };

export const validateAvalReverseOtp =
  (sessionUuid) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_VALIDATED_REVERSE_OTP,
    });
    const payload = {
      session_id: sessionUuid,
    };
    try {
      await collateralService.validateAvalReverseOtp(payload);
      dispatch({
        type: ACTION.RESPONSE_VALIDATED_REVERSE_OTP_DATA,
      });
    } catch (error) {
      console.log('**** error validating reverse otp', error);
      dispatch({
        type: ACTION.ERROR_REVERSE_OTP_DATA,
        error,
      });
    }
  };

export const clearSendCollateralNip = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_SEND_NIP_DATA,
  });
};

export const resendNIPCollateral =
  (user_type_id, user_id, to, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_RESEND_NIP,
    });

    try {
      const nipData = await collateralService.sendCollateralNIP(
        user_type_id,
        user_id,
        to,
        body,
      );
      if (nipData) {
        dispatch({
          type: ACTION.RESPONSE_RESEND_NIP_DATA,
          result: nipData.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_RESEND_NIP_DATA,
        error,
      });
    }
  };

export const clearResendNipDataCollateral = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_RESEND_NIP_DATA,
  });
};

export const confirmCollateralNIP =
  (user_type_id, user_id, to, nip) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_NIP_CONFIRMATION,
    });

    try {
      const nipData = await collateralService.confirmCollateralNIP(
        user_type_id,
        user_id,
        to,
        nip,
      );
      if (nipData) {
        dispatch({
          type: ACTION.RESPONSE_NIP_CONFIRMATION,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_NIP_CONFIRMATION,
        error,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};

export const sendPartnerNewPhoneNumber =
  (user_type_id, user_id, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_PARTNER_NEW_PHONE_NUMBER,
    });

    try {
      const newPhoneData = await collateralService.partnerNewPhoneNumber(
        user_type_id,
        user_id,
        body,
      );
      if (newPhoneData) {
        dispatch({
          type: ACTION.RESPONSE_PARTNER_NEW_PHONE_NUMBER,
          result: newPhoneData,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_PARTNER_NEW_PHONE_NUMBER,
        error,
      });
    }
  };

export const clearPartnerNewPhoneNumber = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PARTNER_NEW_PHONE_NUMBER,
  });
};
