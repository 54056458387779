import * as ACTION from '../actionTypes/collateral';

const collateralState = {
  loading: {
    GET_DATA: false,
    CONFIRM_NIP: false,
    GET_RESEND_NIP_DATA: false,
    PUT_PARTNER_NEW_PHONE: false,
  },
  error: {
    GET_DATA: null,
    CONFIRM_NIP: null,
    GET_RESEND_NIP_DATA: null,
    PUT_PARTNER_NEW_PHONE: null,
  },
  defaultNip: '',
  defaultResendNip: '',
  collateralName: '',
  shopkeeperName: '',
  nipConfirmed: false,
  putPartnerNewPhone: {},
  redirectionUrlObject: {},
  validationResult: '',
};

const collateral = (state = collateralState, action) => {
  switch (action.type) {
    case ACTION.INIT_SEND_NIP:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: true },
        error: { ...state.error, GET_DATA: null },
        validationResult: '',
      };
    case ACTION.INIT_VALIDATED_REVERSE_OTP:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: true },
        error: { ...state.error, GET_DATA: null },
        validationResult: 'loading',
      };
    case ACTION.RESPONSE_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: null },
        defaultNip: action.result.nip,
        collateralName: action.result.collateralName,
        shopkeeperName: action.result.shopkeeperName,
        validationResult: '',
      };
    case ACTION.RESPONSE_REVERSE_OTP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: null },
        redirectionUrlObject: action.result,
        validationResult: '',
      };
    case ACTION.RESPONSE_VALIDATED_REVERSE_OTP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: null },
        validationResult: 'success',
      };
    case ACTION.ERROR_REVERSE_OTP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: action.error },
        validationResult: 'error',
      };
    case ACTION.ERROR_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: action.error },
        defaultNip: '',
        collateralName: '',
        shopkeeperName: '',
        validationResult: '',
      };
    case ACTION.CLEAR_SEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_DATA: false },
        error: { ...state.error, GET_DATA: null },
        defaultNip: '',
        collateralName: '',
        shopkeeperName: '',
        validationResult: '',
      };

    case ACTION.INIT_RESEND_NIP:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: true },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
      };
    case ACTION.RESPONSE_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
        defaultResendNip: action.result.nip,
        collateralName: action.result.collateralName,
        shopkeeperName: action.result.shopkeeperName,
      };
    case ACTION.ERROR_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: {
          ...state.error,
          GET_RESEND_NIP_DATA: action.readableMessage || action.error,
        },
        defaultResendNip: "",
        collateralName: "",
        shopkeeperName: "",
      };
    case ACTION.CLEAR_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
        defaultResendNip: "",
        collateralName: "",
        shopkeeperName: "",
      };

    case ACTION.INIT_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: true },
        error: { ...state.error, CONFIRM_NIP: null },
      };
    case ACTION.RESPONSE_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: null },
        nipConfirmed: true,
      };
    case ACTION.ERROR_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: action.error },
        nipConfirmed: false,
      };

    case ACTION.INIT_PARTNER_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_PARTNER_NEW_PHONE: true },
        error: { ...state.error, PUT_PARTNER_NEW_PHONE: null },
      };
    case ACTION.RESPONSE_PARTNER_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_PARTNER_NEW_PHONE: false },
        error: { ...state.error, PUT_PARTNER_NEW_PHONE: null },
        putPartnerNewPhone: action.result,
      };
    case ACTION.ERROR_PARTNER_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_PARTNER_NEW_PHONE: false },
        error: { ...state.error, PUT_PARTNER_NEW_PHONE: action.error },
        putPartnerNewPhone: {},
      };
    case ACTION.CLEAR_PARTNER_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_PARTNER_NEW_PHONE: false },
        error: { ...state.error, PUT_PARTNER_NEW_PHONE: null },
        putPartnerNewPhone: {},
      };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: collateralState.error };
    default:
      return state;
  }
};

export default collateral;
