import React, { Component } from "react";
import PropTypes from "prop-types";

import LoaderUI from "./Loader";

class Loader extends Component {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    const { reference } = this.props;
    if (reference) reference(this);
  }

  async show() {
    await this.setState({
      isVisible: true,
    });
  }

  async hide() {
    await this.setState({
      isVisible: false,
    });
  }

  render() {
    return <LoaderUI isVisible={this.state.isVisible} />;
  }
}

export default Loader;

Loader.propTypes = {
  reference: PropTypes.any,
};
