import { combineReducers } from "redux";

import originationReducer from "./origination.reducer";
import collateralReducer from "./collateral.reducer";
import catalogsReducer from "./catalogs.reducer";
import advisorReducer from "./advisor.reducer";
import contractReducer from "./contract.reducer";
import operationsReducer from './operations.reducer';

const rootReducer = combineReducers({
  originationReducer,
  collateralReducer,
  catalogsReducer,
  advisorReducer,
  contractReducer,
  operationsReducer,
});

export default rootReducer;
