import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
    paddingBottom: 30,
  },
  // UnitComponent
  imagesContainer: {
    display: "flex",
    backgroundColor: "#3050ff",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  yofioImage: {
    width: "59px",
    height: "36px",
    marginTop: 20,
    position: "absolute",
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    marginBottom: 60,
    color: "#4a4b4b",
  },
}));

export default useStyles;
