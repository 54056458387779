import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
    paddingBottom: 30,
  },
  // UnitComponent
  backgroundImage: {
    width: "100%",
    height: '260px'
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "center",
  },
  yofioImage: {
    width: "59px",
    height: "36px",
    marginTop: 50,
    marginRight: 200,
    position: "absolute",
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    fontSize: '20px'
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    color: "#4a4b4b",
    fontSize: '13px'
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 90,
    marginTop: 30,
  },
  phoneContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  phoneInput: {
    width: "80%",
  },
  warningText: {
    marginRight: 10,
    marginLeft: 10,
    marginTop: 30,
    fontSize: '11px',
    color: '#4a4b4b'
  },
  hiperTextPrivacy: {
    color: "#3050ff",
    fontFamily: "Poppins",
  },
  // NipInput Styles
  mainTitleCode: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 90,
    fontSize: '20px'
  },
  subTitleCode: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 15,
    color: "#4a4b4b",
    fontSize: '13px'
  },
  labelCode: {
    marginTop: 10,
    color: "#4a4b4b",
    fontSize: '11px'
  },
  containerResendNip: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30
  },
  textTime: {
    color: "#3050ff",
    fontSize: '13px',
  },
  continueButton: {
    marginTop: 30
  }
}));

export default useStyles;
