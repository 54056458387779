import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Button, Text, TextInput, CheckBox } from "../../components";
import Stepper from "../../components/Stepper";

export function AdditionalData({
  classes,
  handleChangeCheckbox,
  handleChangeInput,
  handleSubmit,
  values,
  errorMessages,
  _redirectAction
}) {
  return (
    <BoxLayout className={classes.page}>
    <Stepper activeBars={2} activeStep={1}/>
      <BoxLayout className={classes.container}>
        <BoxLayout className={classes.formContainer}>
          <Text
            className={classes.mainTitle}
            variant="h2"
            color="initial"
            align="left"
          >
            Asegúrate que tu  RFC esté correcto
          </Text>
          <TextInput
            className={classes.formElement}
            label="RFC (13 digitos)"
            variant="standard"
            value={values.rfc}
            onChange={handleChangeInput("rfc")}
            helperText={errorMessages.rfc}
            error={errorMessages.rfc !== ""}
            inputProps={{ maxLength: 13 }}
          />
          <a
            onClick={() => _redirectAction()}
            className={classes.hiperTextPrivacy}
          >
            ¿Desconoces tu RFC? Consúltalo aquí
          </a>
          <div className={classes.doubleParamForm}>
            <CheckBox
              onChange={handleChangeCheckbox}
              checked={values.is_my_rfc}
              name="is_my_rfc"
              disabled={
                !(values.rfc.length === 13)
              }
              />
            <Text
              variant="h4"
              align="left"
            >
              Mi RFC es correcto
            </Text>
          </div>
        </BoxLayout>
        <Button
          variant="contained"
          color="primary"
          isDisabled={
            !(
              values.rfc.length === 13 &&
              values.is_my_rfc === true
            )
          }
          onClick={() => handleSubmit()}
        >
          Continuar
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default AdditionalData;

const styles= {
  consultRFC: {
    backgroundColor: "#FFF",
    color: "#3050ff",
  }
};

AdditionalData.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeInput: PropTypes.func,
  handleTrim: PropTypes.func,
  handleDiacritialMarks: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
};
