import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
    paddingBottom: 30,
  },
  // UnitComponent
  stepMark: {
    color: "#3050ff",
  },
  autoCompleteBar: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  fullBar: {
    display: "flex",
    backgroundColor: "#0253cc",
    width: "100%",
    height: 5,
    borderRadius: 5,
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    color: "#4a4b4b",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
    marginTop: 30,
  },
  hiperTextPrivacy: {
    color: "#3050ff",
  },
  // NipInput Styles
  nipContainer: {
    display: "flex",
    backgroundColor: "pink",
    justifyContent: "center",
    alignItems: "center",
  },
  labelNip: {
    color: "#4a4b4b",
  },
  labelErrorNip: {
    color: "#ee4e52",
    marginTop: 10,
  },
  changeNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
