import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    // height: '100%',
    backgroundColor: '#FFFFFF',
    paddingBottom: 30,
  },
  // UnitComponent
  autoCompleteBar: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  fullBar: {
    display: 'flex',
    backgroundColor: '#0253cc',
    width: '100%',
    height: 5,
    borderRadius: 5,
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    color: '#4a4b4b',
  },
  footer: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    fontSize: 10,
  },
  bodyContainerPassword: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 50,
    marginTop: 30,
  },
  bodyContainerValidatePassword: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 80,
  },
  hiperTextPrivacy: {
    color: '#3050ff',
  },
  // NipInput Styles
  nipContainer: {
    display: 'flex',
    backgroundColor: 'pink',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelNip: {
    color: '#4a4b4b',
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelErrorNip: {
    color: '#ee4e52',
    marginTop: 10,
  },
}));

export default useStyles;
