import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Text, Button } from "../../components";
import images from "../../assets/images";

export function SingningSuccess({ classes, success }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.processContainer}>
          <img
            src={images.singProcess}
            className={classes.singProcess}
            alt="img-singProcess"
          />
          <Text
            className={classes.title}
            variant="h5"
            color="initial"
            aling="center"
          >
            ¡Felicidades!
          </Text>
          <Text
            className={classes.text}
            variant="h4"
            color="initial"
            aling="center"
          >
            Has firmado el contrato con éxito, enviaremos una copia una copia
            digital a tu celular.
          </Text>
        </div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => success()}
        >
          Continuar
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default SingningSuccess;

SingningSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
};
