export const INIT_SEND_NIP = "COLLATERAL::INIT_SEND_NIP";
export const RESPONSE_NIP_DATA = "COLLATERAL::RESPONSE_NIP_DATA";
export const ERROR_NIP_DATA = "COLLATERAL::ERROR_NIP_DATA";
export const CLEAR_SEND_NIP_DATA = "COLLATERAL::CLEAR_SEND_NIP_DATA";
export const RESPONSE_REVERSE_OTP_DATA = "COLLATERAL::RESPONSE_REVERSE_OTP_DATA";

export const INIT_VALIDATED_REVERSE_OTP = "COLLATERAL::INIT_VALIDATED_REVERSE_OTP";
export const RESPONSE_VALIDATED_REVERSE_OTP_DATA = "COLLATERAL::RESPONSE_VALIDATED_REVERSE_OTP_DATA";
export const ERROR_REVERSE_OTP_DATA = "COLLATERAL::ERROR_REVERSE_OTP_DATA";

export const INIT_RESEND_NIP = "COLLATERAL::INIT_RESEND_NIP";
export const RESPONSE_RESEND_NIP_DATA = "COLLATERAL::RESPONSE_RESEND_NIP_DATA";
export const ERROR_RESEND_NIP_DATA = "COLLATERAL::ERROR_RESEND_NIP_DATA";
export const CLEAR_RESEND_NIP_DATA = "COLLATERAL::CLEAR_RESEND_NIP_DATA";

export const INIT_NIP_CONFIRMATION = "COLLATERAL::INIT_NIP_CONFIRMATION";
export const RESPONSE_NIP_CONFIRMATION =
  "COLLATERAL::RESPONSE_NIP_CONFIRMATION";
export const ERROR_NIP_CONFIRMATION = "COLLATERAL::ERROR_NIP_CONFIRMATION";

export const INIT_PARTNER_NEW_PHONE_NUMBER =
  "ORIGINATION::INIT_PARTNER_NEW_PHONE_NUMBER";
export const RESPONSE_PARTNER_NEW_PHONE_NUMBER =
  "ORIGINATION::RESPONSE_PARTNER_NEW_PHONE_NUMBER";
export const ERROR_PARTNER_NEW_PHONE_NUMBER =
  "ORIGINATION::ERROR_PARTNER_NEW_PHONE_NUMBER";
export const CLEAR_PARTNER_NEW_PHONE_NUMBER =
  "ORIGINATION::CLEAR_PARTNER_NEW_PHONE_NUMBER";

export const CLEAR_ERRORS = "COLLATERAL::CLEAR_ERRORS";
