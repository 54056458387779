import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Text } from "../../components";
import images from "../../assets/images";

export function ManualVerification({ classes }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.processContainer}>
          <img
            src={images.pausado}
            className={classes.verification}
            alt="img-verification"
          />
          <Text
            className={classes.title}
            variant="h5"
            color="initial"
            aling="center"
          >
            Seguimos trabajando
          </Text>
          <Text
            className={classes.text}
            variant="h4"
            color="initial"
            aling="center"
          >
            Tus documentos están siendo verificados manualmente, contactate con
            la persona de la que seras aval.
          </Text>
        </div>
      </BoxLayout>
    </BoxLayout>
  );
}

export default ManualVerification;

ManualVerification.propTypes = {
  classes: PropTypes.object.isRequired,
};
