import React from "react";

import ButtonUI from "./Button";

function Button(props) {
  const { isDisabled, newCustomStyle } = props;

  const customStyle = !isDisabled
    ? {
        backgroundColor: "#3050ff",
        borderRadius: 20,
        marginRight: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
      }
    : {
        borderRadius: 20,
        marginRight: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
      };

  return (
    <ButtonUI {...props} customStyle={{ ...customStyle, ...newCustomStyle }} />
  );
}

export default Button;
