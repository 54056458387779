/* eslint-disable indent */
import * as ACTION from "../actionTypes/advisor";

const dataState = {
  loading: {
    EVENT_MIXPANEL: false,
  },
  error: {
    EVENT_MIXPANEL: null,
  },
  advisorData: {},
};

const advisorReducer = (state = dataState, action) => {
  switch (action.type) {
    case ACTION.INIT_EVENT_MIXPANEL:
      return {
        ...state,
        loading: { ...state.loading, EVENT_MIXPANEL: true },
        error: { ...state.error, EVENT_MIXPANEL: null },
      };
    case ACTION.RESPONSE_EVENT_MIXPANEL:
      return {
        ...state,
        loading: { ...state.loading, EVENT_MIXPANEL: false },
        error: { ...state.error, EVENT_MIXPANEL: null },
        advisorData: action.result,
      };
    case ACTION.ERROR_EVENT_MIXPANEL:
      return {
        ...state,
        loading: { ...state.loading, EVENT_MIXPANEL: false },
        error: { ...state.error, EVENT_MIXPANEL: action.error },
      };
    case ACTION.CLEAR_STATE:
      return dataState;
    default:
      return state;
  }
};

export default advisorReducer;
