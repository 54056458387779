import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TellMoreAboutUI from "./TellMoreAbout";
import useStyles from "./TellMoreAbout.styles";
import {
  getUserDataDetail,
  postBusiness as postBusinessAction,
  clearPostBusiness,
} from "../../redux/actions/origination.action";

const errorEmptyMsgs = {
  shop_name: "* Ingresa el nombre del negocio !",
  shop_age: "* Selecciona el tiempo con el negocio !",
  business_type: "* Selecciona el giro de la empresa !",
  other_businessType: "* Ingresa tu giro !",
  average_sale: "* Selecciona la venta promedio semanal !",
};

export function TellMoreAbout() {
  const { user_id, phoneNumber } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const saved = sessionStorage.getItem("values");
  const initialValue = JSON.parse(saved);

  const [values, setValues] = useState({
    shop_name: "",
    shop_age: "",
    business_type: "",
    other_businessType: "",
    average_sale: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    shop_name: "",
    shop_age: "",
    business_type: "",
    other_businessType: "",
    average_sale: "",
  });

  const { putBusiness } = useSelector((state) => state.originationReducer);

  const { businessTypeData, salesRangeData } = useSelector(
    (state) => state.catalogsReducer
  );

  useEffect(() => {
    dispatch(getUserDataDetail(user_id, "SK"));
  }, []);

  useEffect(() => {
    if (Object.keys(putBusiness).length > 0) {
      dispatch(clearPostBusiness());
      dispatch(getUserDataDetail(user_id, "SK"));
    }
  }, [putBusiness]);

  const handleChangeInput = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        if (k !== "other_businessType") {
          hasError = true;
          errors[k] = errorEmptyMsgs[k];
        } else {
          errors[k] = "";
        }
      } else {
        errors[k] = "";
      }
    });

    if (
      values.business_type === "Otros" &&
      !validateBusinessType(values.other_businessType)
    ) {
      hasError = true;
      errors.other_businessType = "* Ingresa el giro de tu empresa !";
    }

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    if (values.business_type === "Otros") {
      window.analytics.track("Testing Giro", {
        $phone: phoneNumber,
        names: initialValue.names,
        first_lastname: initialValue.first_lastname,
        second_lastname: initialValue.second_lastname,
        business_type: values.business_type,
        other_businessType: values.other_businessType,
        average_sale: values.average_sale,
      });
    }

    _updateDataBussiness();
  };

  const validateBusinessType = (business_type) => {
    const re = /^[a-zA-Z0-9\s]+$/;
    return re.test(business_type);
  };

  const _updateDataBussiness = () => {
    // Send data to backend
    let body = {
      name: values.shop_name,
      business_type: values.business_type,
      sales_average_range_id: values.average_sale,
      business_age: parseInt(values.shop_age),
    };
    dispatch(postBusinessAction(user_id, "SK", body));
  };

  return (
    <TellMoreAboutUI
      classes={classes}
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
      values={values}
      errorMessages={errorMessages}
      businessTypeData={businessTypeData.data}
      salesRangeData={
        Object.keys(salesRangeData).length == 0
          ? []
          : Object.keys(salesRangeData.data).length === 0
          ? []
          : salesRangeData.data.ranges !== null &&
            salesRangeData.data.ranges.length !== 0
          ? salesRangeData.data.ranges
          : []
      }
    />
  );
}

export default TellMoreAbout;
