import client from "../client";

export const getUserData = (phoneNumber, anonymousId) => {
  let path = `/v1/users?phone_number=${encodeURIComponent(phoneNumber)}`;
  if (anonymousId) {
    path = `/v1/users?phone_number=${encodeURIComponent(
      phoneNumber
    )}&anonymous_id=${anonymousId}`;
  }
  return new Promise((resolve, reject) => {
    client.get(path).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getUserDataDetail = (user_id, user_type_id) => {
  const path = `/v1/users/${user_type_id}/${user_id}`;
  return new Promise((resolve, reject) => {
    client.get(path).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const createRequestOrigination = (body) => {
  const path = "/v2/users";
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendNIP = (user_type_id, user_id, body = {}) => {
  const path = `/v1/users/${user_type_id}/${user_id}/nip`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const confirmNIP = (user_type_id, user_id, nip) => {
  const path = `/v1/users/${user_type_id}/${user_id}/nip`;
  const body = {
    nip,
  };
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const createPwd = (user_type_id, user_id, password) => {
  const path = `/v1/users/${user_type_id}/${user_id}/password`;
  return new Promise((resolve, reject) => {
    client.put(path, { password }).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendAddress = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/addresses`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendCode = (user_type_id, user_id) => {
  const path = `/v1/users/${user_type_id}/${user_id}/code`;
  return new Promise((resolve, reject) => {
    client.post(path, {}).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const confirmCode = (user_type_id, user_id, codeStr) => {
  const path = `/v1/users/${user_type_id}/${user_id}/code`;
  const code = codeStr;
  return new Promise((resolve, reject) => {
    client.put(path, { code }).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendBusinessData = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/businesses`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendBusinessAddressData = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/businesses/address`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendAdditionalData = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/tax_info`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const clientNewPhoneNumber = (user_type_id, user_id, body) => {
  const path = `/v1/users/${user_type_id}/${user_id}/phone-number`;
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const sendNipValidatePhone = (body) => {
  const path = "/v1/users/code";
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const validatePhoneNip = (body) => {
  const path = "/v1/users/code";
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const postReverseOtp = (phoneNumber, anonymousId, ipAddress) => {
  const path = "/v1/users/reverse-otp";
  const body = {
    phone_number: phoneNumber,
    anonymous_id: anonymousId,
    ip_address: ipAddress
  };
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const validateReverseOtp = (sessionId) => {
  const path = "/v1/users/reverse-otp";
  const body = {
    session_id: sessionId
  };
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
