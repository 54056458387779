import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  snackbarContainer: {
    margin: "20px",
    backgroundColor: "#71FC34",
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
  },
  iconLeft: {
    marginRight: 10,
  },
}));

export default useStyles;
