import client from '../client';

export const sendCollateralNIP = (user_type_id, user_id, to, body = {}) => {
  const path = `/v1/users/${user_type_id}/${user_id}/aval/nip?to=${to}`;
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const sendAvalReverseOtp = (body = {}) => {
  const path = '/v1/users/aval/reverse-otp';
  return new Promise((resolve, reject) => {
    client.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const validateAvalReverseOtp = (body = {}) => {
  const path = '/v1/users/aval/reverse-otp';
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const confirmCollateralNIP = (user_type_id, user_id, to, nip) => {
  const path = `/v1/users/${user_type_id}/${user_id}/aval/nip?to=${to}`;
  const body = {
    nip,
  };
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const partnerNewPhoneNumber = (user_type_id, user_id, body = {}) => {
  const path = `/v1/users/${user_type_id}/${user_id}/aval/phone-number`;
  return new Promise((resolve, reject) => {
    client.put(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
