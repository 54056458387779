import React from "react";

import ManualVerificationUI from "./ManualVerification";
import useStyles from "./ManualVerification.styles";

export function ManualVerification() {
  const classes = useStyles();

  return <ManualVerificationUI classes={classes} />;
}

export default ManualVerification;
