import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Hotjar from "@hotjar/browser";

import routes from "./routes";
import "./styles.scss";
import buildStore from "./store";

const { store } = buildStore();

if (process.env.REACT_APP_TRACES_ENVIRONMENT !== "local") {
  Sentry.init({
    dsn: "https://ae737bae8f58441ca283a444db93948c@o445487.ingest.sentry.io/6380424",
    integrations: [
      new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],

    tracesSampleRate: process.env.REACT_APP_TRACES_SAMPLE_RATE,
    environment: process.env.REACT_APP_TRACES_ENVIRONMENT,
  });
}

Hotjar.init(process.env.REACT_APP_HOTJAR_SITE_ID, process.env.REACT_APP_HOTJAR_VERSION);

window.analytics.ready(
  () => {
    const urlParams = new URLSearchParams(window.location.search);
    const anonymousId = urlParams.get("anonymous_id");
    window.analytics.user().anonymousId(anonymousId);
    if (anonymousId) {
      sessionStorage.setItem("anonymous_id", JSON.stringify(anonymousId));
    } else {
      sessionStorage.removeItem("anonymous_id");
    }
  }
);

ReactDOM.render(
  <Provider store={store}>{routes({ store })}</Provider>,
  document.getElementById("root")
);
