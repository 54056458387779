import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { useNavigate, useParams } from "react-router";

import SendSMSPartnerUI from "./SendSMSPartner";
import useStyles from "./SendSMSPartner.styles";
import {
  confirmCollateralNIP,
  sendCollateralNIP,
  clearSendCollateralNip,
  resendNIPCollateral,
  clearResendNipDataCollateral,
  sendPartnerNewPhoneNumber,
  clearPartnerNewPhoneNumber,
} from "../../redux/actions/collateral.action";
import { useQuery } from "../../hooks";

export function SendSMSPartner() {
  const query = useQuery();
  const history = useNavigate();
  let { to, user_id, user_type_id, phoneNumber } = useParams();
  user_id = user_id || query.get("user_id");
  user_type_id =
    user_type_id || query.get("user_type_id");
  to = to || query.get("to");
  phoneNumber =
    phoneNumber || query.get("phone_number");
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    nipConfirmed,
    collateralName,
    shopkeeperName,
    defaultNip: defaultNipFromRedux,
    defaultResendNip,
    putPartnerNewPhone,
  } = useSelector((state) => state.collateralReducer);

  const [nip, setNip] = useState("");
  const [isNipValidate, setIsNipValidate] = useState(false);
  const [showMessageSMS, setShowMessageSMS] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [defaultNip, setDefaultNip] = useState(
    defaultNipFromRedux || defaultResendNip
  );
  const [lada, setLada] = useState("+52");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if (Object.keys(putPartnerNewPhone).length > 0) {
      dispatch(clearPartnerNewPhoneNumber());
    }
  }, [putPartnerNewPhone]);

  useEffect(() => {
    if (user_id) {
      dispatch(sendCollateralNIP(user_type_id, user_id, to));
    }
    return () => {
      dispatch(clearSendCollateralNip());
      dispatch(clearResendNipDataCollateral());
    };
  }, []);

  useEffect(() => {
    if (nipConfirmed) {
      history("/congratulations-partner", { replace: true });
    }
  }, [nipConfirmed]);

  useEffect(() => {
    if (defaultNipFromRedux !== "") {
      setDefaultNip(defaultNipFromRedux);
    }
  }, [defaultNipFromRedux]);

  useEffect(() => {
    if (defaultResendNip !== "") {
      setShowMessageSMS(true);
      setDefaultNip(defaultResendNip);
    }
  }, [defaultResendNip]);

  useEffect(() => {
    if (showMessageSMS) {
      setTimeout(() => {
        onCloseShowMessageSMS();
      }, 10000);
    }
  }, [showMessageSMS]);

  const validateNipNumber = (nipNumberHandle) => {
    const re = /^[0-9]{4}$/im;
    return re.test(nipNumberHandle);
  };

  const handleChangeNip = (value) => {
    const nipNumberHandle = value;
    if (nipNumberHandle !== "") {
      if (!validateNipNumber(nipNumberHandle)) {
        setIsNipValidate(false);
      } else {
        setIsNipValidate(true);
      }
    } else {
      setIsNipValidate(false);
    }
    setNip(value);
  };

  const onContinueNipAction = () => {
    dispatch(confirmCollateralNIP(user_type_id, user_id, to, nip));
  };

  const resendNip = () => {
    if (user_id) {
      dispatch(
        resendNIPCollateral(user_type_id, user_id, to, { resend: true })
      );
    }
  };

  const onCloseShowMessageSMS = () => {
    setShowMessageSMS(false);
  };

  const handleChangeInputLada = () => (event) => {
    setLada(event.target.value);
  };

  const validatePhoneNumber = (phoneNumberHandle) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(phoneNumberHandle);
  };

  const handleChangeNewPhone = (event) => {
    const phoneNumberHandle = event.target.value;
    if (phoneNumberHandle.length <= 10) {
      if (phoneNumberHandle !== "") {
        if (!validatePhoneNumber(phoneNumberHandle)) {
          setIsValidate(false);
        } else {
          setIsValidate(true);
        }
      } else {
        setIsValidate(false);
      }
      setNewPhoneNumber(event.target.value);
    }
  };

  const newNumber = () => {
    const body = {
      aval_type: to,
      phone_number: phoneNumber,
      new_phone_number: `${lada}${newPhoneNumber}`,
    };
    dispatch(sendPartnerNewPhoneNumber(user_type_id, user_id, body));
    setIsVisible(false);
  };

  return (
    <SendSMSPartnerUI
      classes={classes}
      _handleChangeNip={handleChangeNip}
      errorNip=""
      _onContinueNipAction={onContinueNipAction}
      isValidateNip={isNipValidate}
      collateralName={collateralName}
      shopkeeperName={shopkeeperName}
      defaultNip={defaultNip}
      resendNip={resendNip}
      showMessageSMS={showMessageSMS}
      onCloseShowMessageSMS={onCloseShowMessageSMS}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      lada={lada}
      handleChangeInputLada={handleChangeInputLada}
      newPhoneNumber={newPhoneNumber}
      handleChangeNewPhone={handleChangeNewPhone}
      _newNumber={newNumber}
    />
  );
}

SendSMSPartner.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default SendSMSPartner;
