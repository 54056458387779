import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
    paddingBottom: 30,
  },
  // UnitComponent
  yofioImage: {
    width: "59px",
    height: "36px",
    marginTop: 20,
    position: "absolute",
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    color: "#4a4b4b",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 80,
    marginTop: 30,
  },
  hiperTextPrivacy: {
    color: "#3050ff",
  },
  // NipInput Styles
  nipContainer: {
    display: "flex",
    backgroundColor: "pink",
    justifyContent: "center",
    alignItems: "center",
  },
  labelNip: {
    color: "#4a4b4b",
  },
  labelErrorNip: {
    color: "#ee4e52",
    marginTop: 10,
  },
  changeNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    height: "294px",
    borderRadius: 10,
    borderWidth: 10,
    padding: "30px",
    backgroundColor: "#ffffff",
  },
  titleEndorsement: {
    color: "#202122",
    fontWeight: "600",
  },
  textEndorsement: {
    color: "#4a4b4b",
    fontWeight: "normal",
    marginTop: 30,
  },
  phoneContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 50,
  },
  phoneInput: {
    width: "80%",
  },
  modalButton: {
    marginTop: 50,
  },
}));

export default useStyles;
