import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";

import AdditionalDataUI from "./AdditionalData";
import useStyles from "./AdditionalData.styles";
import {
  getUserDataDetail,
  sendAdditionalData,
  clearAdditionalData,
} from "../../redux/actions/origination.action";
import generatedRFC from "../../constants/generatedRFC";

const errorEmptyMsgs = {
  rfc: "* Ingresa el rfc !",
};

export const AdditionalData = () => {
  const { user_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const saved = sessionStorage.getItem("values");
  console.log("Guardados: ", saved);
  const initialValue = JSON.parse(saved);

  const [values, setValues] = useState({
    rfc: "",
    is_my_rfc: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    rfc: "",
  });

  const { putAdditionalData } = useSelector(
    (state) => state.originationReducer
  );

  useEffect(() => {
    if (Object.keys(putAdditionalData).length > 0) {
      dispatch(clearAdditionalData());
      dispatch(getUserDataDetail(user_id, "SK"));
    }
  }, [putAdditionalData]);

  useEffect(() => {
    if (
      initialValue !== undefined &&
      initialValue !== null &&
      initialValue?.names !== "" &&
      initialValue?.first_lastname !== "" &&
      initialValue?.day !== "" &&
      initialValue?.month !== "" &&
      initialValue?.year !== ""
    ) {
      const dateFormat = moment(
        new Date(initialValue?.year, initialValue?.month - 1, initialValue?.day)
      ).format("YYMMDD");
      const rfcGenerated = generatedRFC(
        initialValue.first_lastname,
        initialValue.second_lastname,
        initialValue.names,
        dateFormat
      );
      setValues((prevState) => ({
        ...prevState,
        rfc: rfcGenerated,
      }));
    }
  }, []);

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    if (prop === "rfc") {
      value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    }
    setValues({ ...values, [prop]: value });
  };

  const handleChangeCheckbox = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        hasError = true;
        errors[k] = errorEmptyMsgs[k];
      } else {
        errors[k] = "";
      }
    });

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    _sendAdditionalData();
  };

  const _sendAdditionalData = () => {
    // Send data to backend
    let body = {
      tax_payer_id: values.rfc,
    };
    dispatch(sendAdditionalData("SK", user_id, body));
  };

  const redirectAction = () => {
    window.open("https://www.mi-rfc.com.mx/consulta-rfc-homoclave");
  };

  return (
    <AdditionalDataUI
      classes={classes}
      handleChangeCheckbox={handleChangeCheckbox}
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
      values={values}
      errorMessages={errorMessages}
      _redirectAction={redirectAction}
    />
  );
};

export default AdditionalData;
