/* eslint-disable indent */
import * as ACTION from "../actionTypes/catalogs";

const dataState = {
  loading: {
    GET_CITIES: false,
    GET_ZIP_CODE: false,
    GET_BUSINESS_TYPE: false,
    GET_SALES_RANGE: false
  },
  error: {
    GET_CITIES: null,
    GET_ZIP_CODE: null,
    GET_BUSINESS_TYPE: null,
    GET_SALES_RANGE: null
  },
  citiesData: {},
  zipCodeData: {},
  businessTypeData: {},
  salesRangeData: {}
};

const catalogsReducer = (state = dataState, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_CITIES:
      return {
        ...state,
        loading: { ...state.loading, GET_CITIES: true },
        error: { ...state.error, GET_CITIES: null },
      };
    case ACTION.RESPONSE_GET_CITIES:
      return {
        ...state,
        loading: { ...state.loading, GET_CITIES: false },
        error: { ...state.error, GET_CITIES: null },
        citiesData: action.result,
      };
    case ACTION.ERROR_GET_CITIES:
      return {
        ...state,
        loading: { ...state.loading, GET_CITIES: false },
        error: { ...state.error, GET_CITIES: action.error },
      };
    case ACTION.INIT_GET_ZIP_CODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIP_CODE: true },
        error: { ...state.error, GET_ZIP_CODE: null },
      };
    case ACTION.RESPONSE_GET_ZIP_CODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIP_CODE: false },
        error: { ...state.error, GET_ZIP_CODE: null },
        zipCodeData: action.result,
      };
    case ACTION.ERROR_GET_ZIP_CODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIP_CODE: false },
        error: { ...state.error, GET_ZIP_CODE: action.error },
      };
    case ACTION.CLEAR_GET_ZIP_CODE:
      return { ...state, zipCodeData: {} };

    case ACTION.INIT_GET_BUSSINESS_TYPE:
      return {
        ...state,
        loading: { ...state.loading, GET_BUSINESS_TYPE: true },
        error: { ...state.error, GET_BUSINESS_TYPE: null },
      };
    case ACTION.RESPONSE_GET_BUSSINESS_TYPE:
      return {
        ...state,
        loading: { ...state.loading, GET_BUSINESS_TYPE: false },
        error: { ...state.error, GET_BUSINESS_TYPE: null },
        businessTypeData: action.result,
      };
    case ACTION.ERROR_GET_BUSSINESS_TYPE:
      return {
        ...state,
        loading: { ...state.loading, GET_BUSINESS_TYPE: false },
        error: { ...state.error, GET_BUSINESS_TYPE: action.error },
      };
    case ACTION.INIT_GET_SALES_RANGE:
      return {
        ...state,
        loading: { ...state.loading, GET_SALES_RANGE: true },
        error: { ...state.error, GET_SALES_RANGE: null },
      };
    case ACTION.RESPONSE_GET_SALES_RANGE:
      return {
        ...state,
        loading: { ...state.loading, GET_SALES_RANGE: false },
        error: { ...state.error, GET_SALES_RANGE: null },
        salesRangeData: action.result,
      };
    case ACTION.ERROR_GET_SALES_RANGE:
      return {
        ...state,
        loading: { ...state.loading, GET_SALES_RANGE: false },
        error: { ...state.error, GET_SALES_RANGE: action.error },
      };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataState.error };
    default:
      return state;
  }
};

export default catalogsReducer;
