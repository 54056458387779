import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#009805",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#009805",
    borderBottomWidth: 2
  },
});

function TextInput(props) {
  return (
    <CssTextField {...props} style={{ fontFamily: "Poppins", lineHeight: 1.3 }}>
      {props.children}
    </CssTextField>
  );
}

export default TextInput;
