import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "../../hooks";
import { useDispatch } from "react-redux";

import ReviewDocumentsUI from "./ReviewDocuments";
import { getMati, clearGetMati } from "../../redux/actions/contract.action";

const ReviewDocumentsFunction = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const query = useQuery();

  const verificationType = query.get("verification_type");
  
  useEffect(() => {
    dispatch(clearGetMati());
  }, [verificationType]);

  return <ReviewDocuments 
    { ...props }
    params={params}
    history={history}
    query={query}
    verificationType={verificationType}
  />
}

class ReviewDocuments extends Component {
  constructor(props) {
    super(props);

    let timerToStart = 300;
    if (props.startDate) {
      let fechaActual = new Date(moment().unix());
      let restaFechas = fechaActual.getTime() - props.startDate;
      if (restaFechas < 300) {
        timerToStart = restaFechas;
      } else {
        timerToStart = 0;
      }
    }

    this.state = {
      timer: timerToStart,
      minutes: "05",
      seconds: "00",
    };
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.timer !== 0) {
        this.setState(
          (prevProps) => ({
            timer: prevProps.timer - 1,
          }),
          () => {
            this.converToMinutes();
            this.convertToSeconds();
          }
        );
      } else {
        this.props.history(`/something-happened`, { replace: true });
      }

      if (
        this.state.timer === 270 ||
        this.state.timer === 240 ||
        this.state.timer === 210 ||
        this.state.timer === 180 ||
        this.state.timer === 150 ||
        this.state.timer === 120 ||
        this.state.timer === 90 ||
        this.state.timer === 60 ||
        this.state.timer === 30
      ) {
        this.props.getMati(
          this.props.verificationType,
          this.props.params.user_type_id,
          this.props.params.user_id,
          this.props.params.person_type
        );
      } 
    }, 1000);
  }

  componentDidUpdate() {
    if (Object.keys(this.props.contract.userMati).length == 0) {
      return;
    }
    if (
      this.props.contract.userMati?.data &&
      this.props.contract.userMati.data?.status !== 0
    ) {
      switch (this.props.contract.userMati.data.status) {
        case "APPROVED":
          return this.props.history(
            `/${this.props.params.user_type_id}/${this.props.params.user_id}/${this.props.params.titular_full_name}/${this.props.params.person_type}/${this.props.params.collateral_full_name}/approved-documents`,
            { replace: true }
          );
        case "REJECTED":
          return this.props.history(
            `/${this.props.params.user_type_id}/${this.props.params.user_id}/${this.props.params.titular_full_name}/${this.props.params.person_type}/${this.props.params.collateral_full_name}/error-documents?verification_type=${this.props.verificationType}`,
            { replace: true }
          );
        case "UNDER_REVIEW":
          return this.props.history(
            `/${this.props.params.user_type_id}/${this.props.params.user_id}/${this.props.params.titular_full_name}/${this.props.params.person_type}/${this.props.params.collateral_full_name}/manual-verification`,
            { replace: true }
          );
        default:
          break;
      }
    }
  }

  converToMinutes = () => {
    let { timer } = this.state;
    let minute = Math.floor((timer / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    this.setState({
      minutes: minute,
    });
  };

  convertToSeconds = () => {
    let { timer } = this.state;
    let second = timer % 60;
    second = second < 10 ? "0" + second : second;
    this.setState({
      seconds: second,
    });
  };

  render() {
    return (
      <ReviewDocumentsUI
        minutes={this.state.minutes}
        seconds={this.state.seconds}
      />
    );
  }
}

const mapStateToProps = ({ contractReducer }) => ({
  contract: contractReducer,
});

const mapDispatchToProps = {
  getMati,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDocumentsFunction);
