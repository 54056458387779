import * as ACTION from "../actionTypes/advisor";
import * as advisorService from "../../services/advisor.service";

export const setMixPanelEvents =
  (userId, choice, userType) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_EVENT_MIXPANEL,
    });

    try {
      const advisorData = await advisorService.setMixPanelEvents(
        userId,
        choice,
        userType
      );
      if (advisorData) {
        dispatch({
          type: ACTION.RESPONSE_EVENT_MIXPANEL,
          result: advisorData.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_EVENT_MIXPANEL,
        error,
      });
    }
  };

export const clearStateAdvisor = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_STATE,
  });
};
