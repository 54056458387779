import React, { useState } from "react";

import RejectedByLocationUI from "./RejectedByLocation";
import useStyles from "./RejectedByLocation.styles";

export function RejectedByLocation() {
  const classes = useStyles();

  const _onContinueAction = () => {
    window.location.href = "https://www.yofio.co/";
  };

  return (
    <RejectedByLocationUI
      classes={classes}
      _onContinueAction={_onContinueAction}
    />
  );
}

export default RejectedByLocation;
