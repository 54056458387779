import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router'

import WheresLivingFormUI from "./WheresLivingForm";
import useStyles from "./WheresLivingForm.styles";
import {
  postBusinessAddress,
  clearPostBusinessAddress,
  getUserDataDetail,
} from "../../redux/actions/origination.action";
import {
  getZipCode,
  clearGetZipCode,
} from "../../redux/actions/catalogs.action";
import { useQuery } from "../../hooks";

const errorEmptyMsgs = {
  zip_code: "* Ingresa un código postal !",
  state: "* Ingresa el estado !",
  suburb: "* Selecciona la colonia !",
  street: "* Ingresa la calle !",
  numberStreet: "* Ingresa el número exterior !",
  municipality: "* Selecciona el municipio !",
};

export function WheresLivingForm() {
  const query = useQuery();
  const { user_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const zipCode = query.get("zipCode");
  const direction =query.get("direction");
  const streetNumber = query.get("streetNumber")

  const [values, setValues] = useState({
    zip_code: "",
    state: "",
    suburb: "",
    street: "",
    numberStreet: "",
    apartmentNumber: "",
    municipality: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    zip_code: "",
    state: "",
    suburb: "",
    street: "",
    numberStreet: "",
    apartmentNumber: "",
    municipality: "",
  });

  const { postBusinessAddress : postBusinessAddressData } = useSelector((state) => state.originationReducer);

  const { zipCodeData } = useSelector((state) => state.catalogsReducer);

  useEffect(() => {
    if (Object.keys(postBusinessAddressData).length > 0) {
      dispatch(clearPostBusinessAddress());
      dispatch(getUserDataDetail(user_id, "SK"));
    }
  }, [postBusinessAddressData]);

  /**
    * Direccion automatica
  */
  
  useEffect(() => {
    if(zipCode && zipCode.length === 5) {
      setValues((prevState) => ({
        ...prevState,
        zip_code: zipCode,
        street: direction,
        numberStreet: streetNumber
      }))
    }
  }, [zipCode]);

  useEffect(() => {
    if (values.zip_code.length === 5) {
      dispatch(getZipCode(values.zip_code));
    } else {
      dispatch(clearGetZipCode());
    }
  }, [values.zip_code]);

  useEffect(() => {
    if (values.suburb !== "") {
      const newAddress = zipCodeData.data.data.filter(
        (addressObject) => addressObject.neighborhood === values.suburb
      );
      if (newAddress.length !== 0) {
        setValues((prevState) => ({
          ...prevState,
          state: newAddress[0].state,
          municipality: newAddress[0].city,
          suburb: newAddress[0].neighborhood,
        }));
      }
    }
  }, [values.suburb]);

  useEffect(() => {
    if (Object.keys(zipCodeData).length !== 0) {
      if (Object.keys(zipCodeData.data).length !== 0) {
        if (
          zipCodeData.data.data !== null &&
          zipCodeData.data.data.length !== 0
        ) {
          setValues((prevState) => ({
            ...prevState,
            state: zipCodeData.data.data[0].state,
            municipality: zipCodeData.data.data[0].city,
            suburb: zipCodeData.data.data[0].neighborhood,
          }));
        } else {
          setValues((prevState) => ({
            ...prevState,
            state: "",
            municipality: "",
            suburb: "",
          }));
        }
      } else {
        setValues((prevState) => ({
          ...prevState,
          state: "",
          municipality: "",
          suburb: "",
        }));
      }
    } else {
      setValues((prevState) => ({
        ...prevState,
        state: "",
        municipality: "",
        suburb: "",
      }));
    }
  }, [zipCodeData]);

  const handleChangeInput = (prop) => (event) => {
    if (prop === "zip_code") {
      if (event.target.value.length <= 5)
        setValues({ ...values, [prop]: event.target.value });
    } else if (prop === "street") {
      if (event.target.value.includes("&")) {
        setErrorMessages({ ...errorMessages, [prop]: 'El carácter "&" no está permitido.' });
      } else {
        setErrorMessages({ ...errorMessages, [prop]: '' });
        setValues({ ...values, [prop]: event.target.value });
      }
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeCheckbox = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        if (k !== "apartmentNumber") {
          hasError = true;
          errors[k] = errorEmptyMsgs[k];
        }
      } else {
        errors[k] = "";
      }
    });

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    _sendAddressData();
  };

  const _sendAddressData = () => {
    // Send data to backend
    const body = {
      zip_code: values.zip_code,
      street_name: values.street,
      apartment_number: values.apartmentNumber,
      street_number: values.numberStreet,
      neighborhood: values.suburb,
      state: values.state,
      city: values.municipality,
    };
    dispatch(postBusinessAddress(user_id, "SK", body));
  };

  return (
    <WheresLivingFormUI
      classes={classes}
      handleChangeInput={handleChangeInput}
      handleChangeCheckbox={handleChangeCheckbox}
      handleSubmit={handleSubmit}
      values={values}
      errorMessages={errorMessages}
      zipCodeData={
        Object.keys(zipCodeData).length == 0
          ? []
          : Object.keys(zipCodeData.data).length === 0
          ? []
          : zipCodeData.data.data !== null && zipCodeData.data.data.length !== 0
          ? zipCodeData.data.data
          : []
      }
    />
  );
}

export default WheresLivingForm;
