import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stepperIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '12px',
    marginTop: '10px',
  },
  stepBox: {
    width: '40px',
    height: '40px',
    borderRadius: '50px',
    backgroundColor: '#3050ff',
    marginRight: theme.spacing(2),
  },
  stepText: {
    marginBottom: theme.spacing(1),
    color: '#666e75',
    fontWeight: 'normal',
    fontSize: '12px',
  },
  stepInfoText: {
    marginBottom: theme.spacing(1),
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '16px',
  },
  stepIndicator: {
    width: '50px',
    height: '3px',
    borderRadius: '10px',
    backgroundColor: '#e2eaf0',
    marginLeft: theme.spacing(1),
  },
  activeStepIndicator: {
    backgroundColor: '#3050ff',
  },
}));

export default useStyles;
