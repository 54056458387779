import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import AboutYouFormUI from "./AboutYouForm";
import useStyles from "./AboutYouForm.styles";
import {
  getUserDataDetail,
  createRequestOrigination,
  clearRequestOrigination,
} from "../../redux/actions/origination.action";

import { useQuery } from "../../hooks";

const errorEmptyMsgs = {
  names: "* Ingresa los nombres !",
  first_lastname: "* Ingresa el apellido paterno !",
  day: "* Ingresa tu día de nacimiento !",
  month: "* Ingresa tu mes de nacimiento !",
  year: "* Ingresa tu año de nacimiento !",
  time_here: "* Selecciona el tiempo viviendo ahí !",
  civil_status: "* Selecciona su estado civil !",
};

export function AboutYouForm() {
  const query = useQuery();
  const { phoneNumber } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const utmSource =
    query.get("utm_source") || sessionStorage.getItem("utm_source");
  const utmMedium =
    query.get("utm_medium") || sessionStorage.getItem("utm_medium");
  const utmCampaign =
    query.get("utm_campaign") || sessionStorage.getItem("utm_campaign");
  const salesIdentifier = query.get("sales_identifier");
  const token = query.get("token");
  const [date, setDate] = useState("");
  const [values, setValues] = useState({
    names: "",
    first_lastname: "",
    second_lastname: "",
    day: "",
    month: "",
    year: "",
    time_here: "",
    civil_status: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    names: "",
    first_lastname: "",
    second_lastname: "",
    day: "",
    month: "",
    year: "",
    time_here: "",
    civil_status: "",
  });

  const { postUserData } = useSelector((state) => state.originationReducer);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("clientToken", `Bearer ${token}`);
    }
  }, [token]);

  useEffect(() => {
    if (Object.keys(postUserData).length > 0) {
      dispatch(clearRequestOrigination());
      dispatch(
        getUserDataDetail(
          postUserData.data.user_id,
          postUserData.data.user_type
        )
      );
      sessionStorage.setItem(
        "clientToken",
        `Bearer ${postUserData.data.authentication.access_token}`
      );
    }
  }, [postUserData]);

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    setValues({ ...values, [prop]: value });
  };

  const handleTrim = (prop) => (event) => {
    let { value } = event.target;
    value = (value || "").trim();
    setValues({ ...values, [prop]: value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };
  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        if (k !== "second_lastname") {
          hasError = true;
          errors[k] = errorEmptyMsgs[k];
        } else {
          errors[k] = "";
        }
      } else {
        errors[k] = "";
      }
    });

    if (!validateName(values.names)) {
      hasError = true;
      errors.names =
        "* El formato del nombre es inválido, solo se aceptan letras y espacios";
    }

    if (!validateLastname(values.first_lastname)) {
      hasError = true;
      errors.first_lastname =
        "* El formato del apellido paterno es inválido, solo se aceptan letras.";
    }

    if (values.second_lastname && !validateLastname(values.second_lastname)) {
      hasError = true;
      errors.second_lastname =
        "* El formato del apellido materno es inválido, solo se aceptan letras.";
    }

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    sessionStorage.setItem("values", JSON.stringify(values));
    _createNewUser();
  };

  const validateName = (name) => {
    const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]{2,}(\s[a-zA-ZÀ-ÿ\u00f1\u00d1]{2,})*$/;
    return re.test(name);
  };

  const validateLastname = (lastname) => {
    const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]{2,}(\s[a-zA-ZÀ-ÿ\u00f1\u00d1]{2,})*$/;
    return re.test(lastname);
  };

  const _createNewUser = () => {
    const anonymousId = JSON.parse(sessionStorage.getItem("anonymous_id"));
    const version = sessionStorage.getItem("version");
    // Send data to backend
    let body = {
      account_type_id: "SK",
      name: values.names,
      lastname: values.first_lastname,
      maternal_lastname: values.second_lastname,
      dob: date,
      length_of_residency_in_years: parseInt(values.time_here),
      marital_status: values.civil_status,
      phone_number: phoneNumber,
      utm_source: utmSource === null || utmSource === "null" ? "" : utmSource,
      utm_name:
        utmCampaign === null || utmCampaign === "null" ? "" : utmCampaign,
      utm_medium: utmMedium === null || utmMedium === "null" ? "" : utmMedium,
      sales_identifier:
        salesIdentifier === null || salesIdentifier === "null"
          ? ""
          : salesIdentifier,
    };

    if (anonymousId) {
      body = {
        ...body,
        anonymous_id: anonymousId,
      };
    }
    if (version !== undefined || version !== null) {
      body = {
        ...body,
        kiban_flow_version_id: parseInt(version, 10),
      };
    }
    dispatch(createRequestOrigination(body));
  };

  return (
    <AboutYouFormUI
      classes={classes}
      handleChangeInput={handleChangeInput}
      handleTrim={handleTrim}
      handleSubmit={handleSubmit}
      values={values}
      errorMessages={errorMessages}
      date={date}
      setDate={setDate}
    />
  );
}

export default AboutYouForm;
