import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "640px",
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  title: {
    color: "#2b2c49",
    fontFamily: "Poppins-Medium",
    fontWeight: "normal",
    textAlign: "left",
    fontSize: 27,
    marginLeft: 20,
    marginTop: 100,
  },
  text: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    fontWeight: "normal",
    marginLeft: 20,
    marginTop: 25,
  },
  singContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(246, 249, 252, 0.5)",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid rgba(158, 173, 187, 0.8)",
    borderRadius: 10,
    margin: 10,
    marginTop: 20,
    width: "300px",
    paddingTop: 25,
    paddingBottom: 25
  },
  singIcon: {
    marginBottom: 15,
  },
  signText: {
    marginBottom: 5,
    fontFamily: "Poppins-Regular",
    fontSize: 15,
  },
  textEndorsement: {
    color: "#000",
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10
  },
  itemContainerWhatsApp: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "green",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 30,
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 15,
  },
  textWhats: {
    color: "#FFF",
  },
  // ContainerModal
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "300px",
    borderRadius: 15,
    backgroundColor: "#fff",
  },
  titleModal: {
    color: "#000",
    fontSize: 15,
    textAlign: "center",
    padding: 25
  },
}));

export default useStyles;
