import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./redux/reducers";

const buildStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const composedMiddleware = applyMiddleware(thunk);

  const initialState = {};

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(composedMiddleware)
  );

  return {
    store,
  };
};

export default buildStore;
