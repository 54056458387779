import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Text } from "../../components";
import images from "../../assets/images";
import useStyles from "./ReviewDocuments.styles";

export function ReviewDocuments({ minutes, seconds }) {
  const classes = useStyles();

  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.reviewContainer}>
          <img
            src={images.pausado}
            className={classes.reviewProcess}
            alt="img-reviewer"
          />
          <Text
            className={classes.title}
            variant="h5"
            color="initial"
            aling="center"
          >
            Espera un momento, estamos revisando tus documentos
          </Text>
          <div className={classes.timer}>
            <Text
              className={classes.numberText}
              variant="h5"
              color="initial"
              aling="center"
            >
              {minutes}:
            </Text>
            <Text
              className={classes.numberText}
              variant="h5"
              color="initial"
              aling="center"
            >
              {seconds}
            </Text>
          </div>
          <div className={classes.textTimer}>
            <Text
              className={classes.text}
              variant="subtitle1"
              color="initial"
              aling="center"
            >
              minutos
            </Text>
            <Text
              className={classes.text1}
              variant="subtitle1"
              color="initial"
              aling="center"
            >
              segundos
            </Text>
          </div>
        </div>
      </BoxLayout>
    </BoxLayout>
  );
}

export default ReviewDocuments;

ReviewDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};
