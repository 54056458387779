import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import OneSignal from "react-onesignal";
import { BsWhatsapp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import useStyles from "./App.styles";
import { BoxLayout, Loader, ErrorHandler } from "../components";
import Text from "../components/Text";
import { darkTheme, lightTheme } from "./Theme";
import { clearErrors } from "../redux/actions/origination.action";
import { clearErrors as collateralClearErrors } from "../redux/actions/collateral.action";
import {
  getCities,
  getBusinessTypes,
  getSalesRange,
  clearErrors as catalogsClearErrors,
} from "../redux/actions/catalogs.action";

const replace = true;

const App = (props) => {
  const { children } = props;

  const loadingHandler = useRef(null);
  const errorHandler = useRef(null);

  const dispatch = useDispatch();

  const history = useNavigate();
  const { isLightThemeSelected } = useState(true);
  const [timer, setTimer] = useState(10);
  const classes = useStyles();

  const {
    loading,
    error,
    userData,
    userDataDetail,
    authData,
    codeConfirmed,
    newUser,
    postUserData,
    isLoadingKibanShopkeeper,
  } = useSelector((state) => state.originationReducer);

  const { loading: collateralLoading, error: collateralError } = useSelector(
    (state) => state.collateralReducer
  );

  const { loading: catalogsLoading, error: catalogsError } = useSelector(
    (state) => state.catalogsReducer
  );

  const { loading: advisorLoading, error: advisorError } = useSelector(
    (state) => state.advisorReducer
  );

  useEffect(() => {
    if (postUserData) {
      if (postUserData.data && (codeConfirmed || newUser)) {
        if (postUserData.data.authentication) {
          sessionStorage.setItem(
            "clientToken",
            `${postUserData.data.authentication.access_type} ${postUserData.data.authentication.access_token}`
          );
        }
      }
    }
  }, [postUserData]);

  const setStep = (ud) => {
    if (ud) {
      if (ud.data) {
        const phone_number = sessionStorage.getItem("phone_number");
        if (ud.data.authentication) {
          sessionStorage.setItem(
            "clientToken",
            `${ud.data.authentication.access_type} ${ud.data.authentication.access_token}`
          );
        }
        const {
          next_step: next_step_data,
          user_id: user_id_data,
          id: id_data,
          status: status_data,
        } = ud.data;
        const { next_step: next_step_request, status: status_request } =
          ud.data.request || {};
        const user_id = user_id_data || id_data;
        const next_step = next_step_data || next_step_request;
        const status = status_data || status_request;
        if (!next_step || !user_id) {
          return;
        }
        Sentry.setUser({ username: phone_number, id: user_id });
        switch (next_step) {
          case "BUSINESS_ADDRESS":
            history(`/${phone_number}/${user_id}/search-address`, { replace });
            break;
          case "ABOUT_STORE":
            history(`/${phone_number}/${user_id}/more-about`, { replace });
            break;
          case "ACCOUNT_NIP":
            if (status === "WAITING") {
              return history(`/${user_id}/waiting`, { replace });
            } else if (status === "IN_PROGRESS") {
              return history(`/${phone_number}/${user_id}/send-nip`, {
                replace,
              });
            } else {
              return history("/rejected", { replace });
            }
          case "ACCOUNT_BUREAU":
            history(`/${phone_number}/${user_id}/send-nip?already-sent=true`, {
              replace,
            });
            break;
          case "PASSWORD":
            if (status === "WAITING") {
              return history(`/${user_id}/waiting`, { replace });
            } else if (status === "IN_PROGRESS") {
              return history(`/${phone_number}/${user_id}/create-password`, {
                replace,
              });
            } else {
              return history("/rejected", { replace });
            }
            break;
          case "INVENTORY":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "PARTNER_FORM":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "PARTNER_NIP":
            let partnerData =
              ud.data?.avals && ud.data.avals.length !== 0
                ? ud.data.avals.filter(
                    (partner) => partner.aval_type === "PARTNER"
                  )
                : [];
            let phoneNumberPartner =
              partnerData.length !== 0 ? partnerData[0].phone : "+581234567890";
            history(
              `/partner/SK/${user_id}/${phoneNumberPartner}/send-nip-partner`,
              { replace }
            );
            break;
          case "PARTNER_BUREAU":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "COLLATERAL_FORM":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "COLLATERAL_NIP":
            let collateralData =
              ud.data?.avals && ud.data.avals.length !== 0
                ? ud.data.avals.filter(
                    (partner) => partner.aval_type === "COLLATERAL"
                  )
                : [];
            let phoneNumberCollateral =
              collateralData.length !== 0
                ? collateralData[0].phone
                : "+581234567890";
            history(
              `/collateral/SK/${user_id}/${phoneNumberCollateral}/send-nip-partner`,
              { replace }
            );
            break;
          case "COLLATERAL_BUREAU":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "WISH_NEW_COLLATERAL":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "ASK_NEW_COLLATERAL":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "ASK_REPLACE_PARTNER":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "PENDING_APPROVAL":
            history(`/${phone_number}/${user_id}/download`, { replace });
            break;
          case "TAX_ACCOUNT":
            history(`/${phone_number}/${user_id}/additional-data`, { replace });
            break;
          default:
            history(`/${phone_number}/${user_id}/advisor-contact`, { replace });
            break;
        }
      }
    }
  };

  useEffect(() => {
    setStep(userData);
  }, [userData]);

  useEffect(() => {
    setStep(userDataDetail);
  }, [userDataDetail]);

  useEffect(() => {
    if (
      authData.data &&
      authData.data.access_type &&
      authData.data.access_token
    ) {
      sessionStorage.setItem(
        "clientToken",
        `${authData.data.access_type} ${authData.data.access_token}`
      );
    }
  }, [authData]);

  useEffect(() => {
    const {
      GET_USER_DATA,
      GET_USER_DATA_DETAIL,
      POST_USER,
      GET_NIP_DATA,
      CONFIRM_NIP,
      CREATE_PWD,
      POST_ADDRESS,
      PUT_BUSINESS,
      POST_BUSINESS_ADDRESS,
      PUT_ADDITIONAL_DATA,
      GET_RESEND_NIP_DATA,
      SEND_NIP_PHONE,
      VALIDATE_NIP_PHONE,
    } = loading;

    if (!isLoadingKibanShopkeeper) {
      if (
        GET_USER_DATA ||
        POST_USER ||
        GET_NIP_DATA ||
        CONFIRM_NIP ||
        CREATE_PWD ||
        POST_ADDRESS ||
        PUT_BUSINESS ||
        POST_BUSINESS_ADDRESS ||
        GET_USER_DATA_DETAIL ||
        PUT_ADDITIONAL_DATA ||
        GET_RESEND_NIP_DATA ||
        SEND_NIP_PHONE ||
        VALIDATE_NIP_PHONE
      ) {
        loadingHandler.current.show();
      } else {
        loadingHandler.current.hide();
      }
    }
  }, [loading]);

  useEffect(() => {
    const { GET_DATA, CONFIRM_NIP, GET_RESEND_NIP_DATA } = collateralLoading;

    if (GET_DATA || CONFIRM_NIP || GET_RESEND_NIP_DATA) {
      loadingHandler.current.show();
    } else {
      loadingHandler.current.hide();
    }
  }, [collateralLoading]);

  useEffect(() => {
    const { GET_CITIES, GET_ZIP_CODE, GET_BUSINESS_TYPE } = catalogsLoading;

    if (GET_CITIES || GET_ZIP_CODE || GET_BUSINESS_TYPE) {
      loadingHandler.current.show();
    } else {
      loadingHandler.current.hide();
    }
  }, [catalogsLoading]);

  useEffect(() => {
    const { EVENT_MIXPANEL } = advisorLoading;

    if (EVENT_MIXPANEL) {
      loadingHandler.current.show();
    } else {
      loadingHandler.current.hide();
    }
  }, [advisorLoading]);

  useEffect(() => {
    const {
      GET_USER_DATA,
      GET_USER_DATA_DETAIL,
      POST_USER,
      GET_NIP_DATA,
      CONFIRM_NIP,
      CREATE_PWD,
      POST_ADDRESS,
      PUT_BUSINESS,
      POST_BUSINESS_ADDRESS,
      PUT_ADDITIONAL_DATA,
      GET_RESEND_NIP_DATA,
      SEND_NIP_PHONE,
      VALIDATE_NIP_PHONE,
    } = error;

    if (GET_USER_DATA) {
      if (GET_USER_DATA.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(GET_USER_DATA);
      }
    }

    if (POST_USER) {
      if (POST_USER.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(POST_USER);
      }
    }

    if (GET_NIP_DATA) {
      if (GET_NIP_DATA.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(GET_NIP_DATA);
      }
    }

    if (CONFIRM_NIP) {
      if (CONFIRM_NIP.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(CONFIRM_NIP);
      }
    }

    if (CREATE_PWD) {
      if (CREATE_PWD.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(CREATE_PWD);
      }
    }

    if (POST_ADDRESS) {
      if (POST_ADDRESS.response?.status === 422 || POST_ADDRESS.response?.status === 412) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(POST_ADDRESS);
      }
    }

    if (PUT_BUSINESS) {
      if (PUT_BUSINESS.response?.status === 422 || PUT_BUSINESS.response?.status === 412) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(PUT_BUSINESS);
      }
    }

    if (POST_BUSINESS_ADDRESS) {
      if (POST_BUSINESS_ADDRESS.response?.status === 422 || POST_BUSINESS_ADDRESS.response?.status === 412) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(POST_BUSINESS_ADDRESS);
      }
    }

    if (GET_USER_DATA_DETAIL) {
      if (GET_USER_DATA_DETAIL.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(GET_USER_DATA_DETAIL);
      }
    }

    if (PUT_ADDITIONAL_DATA) {
      if (PUT_ADDITIONAL_DATA.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(PUT_ADDITIONAL_DATA);
      }
    }

    if (GET_RESEND_NIP_DATA) {
      if (GET_RESEND_NIP_DATA.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(GET_RESEND_NIP_DATA);
      }
    }

    if (SEND_NIP_PHONE) {
      if (SEND_NIP_PHONE.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(SEND_NIP_PHONE);
      }
    }

    if (VALIDATE_NIP_PHONE) {
      if (VALIDATE_NIP_PHONE.response?.status === 422) {
        history("/rejected", { replace });
      } else {
        errorHandler.current.show(VALIDATE_NIP_PHONE);
      }
    }
  }, [error]);

  useEffect(() => {
    const { GET_DATA, CONFIRM_NIP, GET_RESEND_NIP_DATA } = collateralError;

    if (GET_DATA) {
      errorHandler.current.show(GET_DATA);
    }

    if (CONFIRM_NIP) {
      errorHandler.current.show(CONFIRM_NIP);
    }

    if (GET_RESEND_NIP_DATA) {
      errorHandler.current.show(GET_RESEND_NIP_DATA);
    }
  }, [collateralError]);

  useEffect(() => {
    const { GET_CITIES, GET_ZIP_CODE, GET_BUSINESS_TYPE } = catalogsError;

    if (GET_CITIES) {
      errorHandler.current.show(GET_CITIES);
    }

    if (GET_ZIP_CODE) {
      errorHandler.current.show(GET_ZIP_CODE);
    }

    if (GET_BUSINESS_TYPE) {
      errorHandler.current.show(GET_BUSINESS_TYPE);
    }
  }, [catalogsError]);

  useEffect(() => {
    const { EVENT_MIXPANEL } = advisorError;

    if (EVENT_MIXPANEL) {
      errorHandler.current.show(EVENT_MIXPANEL);
    }
  }, [advisorError]);

  useEffect(() => {
    /**
     * OneSignal Configuration
     */
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_ID,
    });

    /**
     * Cargar catalogos
     */
    dispatch(getCities());
    dispatch(getBusinessTypes());
    dispatch(getSalesRange())
  }, []);

  return (
    <MuiThemeProvider theme={isLightThemeSelected ? lightTheme : darkTheme}>
      <BoxLayout className={classes.container}>
        {children}

        <div
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_WHATSAPP_LINK}&text=Tengo%20dudas%20con%20mi%20registro`)
          }
          className={classes.whatsAppContainer}
        >
          <BsWhatsapp className={classes.whatsAppIcon} />
        </div>
        <Loader
          reference={(reference) => {
            loadingHandler.current = reference;
          }}
        />
        <ErrorHandler
          actionText="Entendido"
          reference={(reference) => {
            errorHandler.current = reference;
          }}
          onClose={() => {
            errorHandler.current.hide();
            loadingHandler.current.hide();
            dispatch(clearErrors());
            dispatch(collateralClearErrors());
            dispatch(catalogsClearErrors());
          }}
        />
      </BoxLayout>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}),
};

App.defaultProps = {
  classes: {},
};

export default App;
