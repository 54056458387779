import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Text, Button } from "../../components";
import images from "../../assets/images";

export function ApprovedDocuments({ classes, singningContract }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.processContainer}>
          <img
            src={images.exito}
            className={classes.approvedDocuments}
            alt="img-approved"
          />
          <Text
            className={classes.title}
            variant="h5"
            color="initial"
            aling="center"
          >
            ¡Fueron aprobados tus documentos!
          </Text>
          <Text
            className={classes.text}
            variant="h4"
            color="initial"
            aling="center"
          >
            Puedes continuar con la parte de la firma del contrato.
          </Text>
        </div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => singningContract()}
        >
          Continuar
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default ApprovedDocuments;

ApprovedDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};
