import React, { useState, useEffect } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import SendSMSUI from "./SendSMS";
import useStyles from "./SendSMS.styles";
import {
  confirmNIP,
  sendNIP,
  clearSendNipData,
  resendNIP,
  clearResendNipData,
  getUserDataDetail,
} from "../../redux/actions/origination.action";
import { useQuery } from "../../hooks";

export function SendSMS() {
  const { phoneNumber, user_id } = useParams();
  const query = useQuery();

  const classes = useStyles();
  const dispatch = useDispatch();
  const alreadySent = query.get("already-sent");

  const {
    nipConfirmed,
    defaultNip: defaultNipFromRedux,
    defaultResendNip,
  } = useSelector((state) => state.originationReducer);

  const [nip, setNip] = useState("");
  const [isNipValidate, setIsNipValidate] = useState(false);
  const [showMessageSMS, setShowMessageSMS] = useState(false);
  const [defaultNip, setDefaultNip] = useState(
    defaultNipFromRedux || defaultResendNip
  );
  
  useEffect(() => {
    if (!alreadySent) {
      dispatch(sendNIP("SK", user_id));
    }
    return () => {
      dispatch(clearSendNipData());
      dispatch(clearResendNipData());
    };
  }, []);

  useEffect(() => {
    if (nipConfirmed) {
      dispatch(getUserDataDetail(user_id, "SK"));
    }
  }, [nipConfirmed]);

  useEffect(() => {
    if (defaultNipFromRedux !== "") {
      setDefaultNip(defaultNipFromRedux);
    }
  }, [defaultNipFromRedux]);

  useEffect(() => {
    if (defaultResendNip !== "") {
      setShowMessageSMS(true);
      setDefaultNip(defaultResendNip);
    }
  }, [defaultResendNip]);

  useEffect(() => {
    if (showMessageSMS) {
      setTimeout(() => {
        onCloseShowMessageSMS();
      }, 10000);
    }
  }, [showMessageSMS]);

  const validateNipNumber = (nipNumberHandle) => {
    const re = /^[0-9]{4}$/im;
    return re.test(nipNumberHandle);
  };

  const handleChangeNip = (value) => {
    const nipNumberHandle = value;
    if (nipNumberHandle !== "") {
      if (!validateNipNumber(nipNumberHandle)) {
        setIsNipValidate(false);
      } else {
        setIsNipValidate(true);
      }
    } else {
      setIsNipValidate(false);
    }
    setNip(value);
  };

  const onContinueNipAction = () => {
    dispatch(confirmNIP("SK", user_id, nip));
  };

  const resendNip = () => {
    dispatch(resendNIP("SK", user_id, { resend: true }));
  };

  const onCloseShowMessageSMS = () => {
    setShowMessageSMS(false);
  };

  return (
    <SendSMSUI
      classes={classes}
      _handleChangeNip={handleChangeNip}
      errorNip=""
      _onContinueNipAction={onContinueNipAction}
      isValidateNip={isNipValidate}
      phoneNumber={phoneNumber.toString()}
      defaultNip={defaultNip}
      resendNip={resendNip}
      showMessageSMS={showMessageSMS}
      onCloseShowMessageSMS={onCloseShowMessageSMS}
    />
  );
}

SendSMS.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default SendSMS;
