import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";

import SearchAddressUI from "./SearchAddress";
import useStyles from "./SearchAddress.styles";

export function SearchAddress() {
  const history = useNavigate();
  const { phoneNumber, user_id } = useParams();
  const classes = useStyles();
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  const apiKey = process.env.REACT_APP_API_KEY_GOOGLE;
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, []);

  function loadAsyncScript(src) {
    return new Promise(resolve => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src
      })
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    })
  };

   // init gmap script
   const initMapScript = () => {
    // if script already loaded
    if(window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }

   // do something on address change
   const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  }

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
    autocomplete.setFields(["address_component", "formatted_address", "geometry", "name"]);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    autocomplete.setComponentRestrictions({
      country: ["mx"],
    });
  }

  const extractAddress = (place) => {

    const address = {
      direction: "",
      streetNumber: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    }
    
  
    if (!Array.isArray(place?.address_components)) {
      return address;
    }
  
    place.address_components.forEach(component => {
      const types = component.types;
      const value = component.long_name;
      
      if (types.includes("route")) {
        address.direction = value;
      }

      if (types.includes("street_number")) {
        address.streetNumber = value;
      }

      if (types.includes("locality")) {
        address.city = value;
      }
  
      if (types.includes("administrative_area_level_2")) {
        address.state = value;
      }
  
      if (types.includes("postal_code")) {
        address.zip = value;
      }
  
      if (types.includes("country")) {
        address.country = value;
      }
  
    });
  
    return address;
  }

  const addressInformation = () => {
    if(Object.keys(address).length !== 0) {
      history(
        `/${phoneNumber}/${user_id}/about-living?zipCode=${address.zip}&direction=${address.direction}&streetNumber=${address.streetNumber}`, 
        { replace: true }
      );
 
    }
  };

  const addressDontInformation = () => {
    history(`/${phoneNumber}/${user_id}/about-living?zipCode=""&direction=""&streetNumber=""`, { replace: true });
  };
  
  return (
    <SearchAddressUI
      classes={classes}
      searchInput={searchInput}
      address={address}
      addressInformation={addressInformation}
      addressDontInformation={addressDontInformation}
    />
  );
}

export default SearchAddress;
