import operationsClient from '../operationsClient';

export const postAssignLead = (body = {}) => {
  const path = '/v1/lead-assignment';
  return new Promise((resolve, reject) => {
    operationsClient.post(path, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
