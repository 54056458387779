import React from "react";
import PropTypes from "prop-types";

import {
  BoxLayout,
  Button,
  Text,  
} from "../../components";
import "./SearchAddress.css";
import Stepper from "../../components/Stepper";

export function SearchAddress({
  classes,
  searchInput,
  address,
  addressInformation,
  addressDontInformation,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
      <Stepper activeBars={4} activeStep={1}/>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          Domicilio de tu negocio
        </Text>
        <BoxLayout className={classes.formContainer}>
          <div className={classes.search}>
            <input className="input" ref={searchInput} type="text" placeholder="Escribe tu dirección"/>
          </div>
          <Button
            newCustomStyle={styles.newStyleButton}
            variant="contained"
            color="primary"
            onClick={() => addressDontInformation()}
          >
            No encontre mi domicilio
          </Button>
          <Button
            newCustomStyle={styles.newStyleButton}
            variant="contained"
            color="primary"
            isDisabled={!(Object.keys(address).length !== 0)}
            onClick={() => addressInformation()}
          >
            Continuar
          </Button>
        </BoxLayout>
      </BoxLayout>
    </BoxLayout>
  );
}

export default SearchAddress;

const styles = {
  newStyleButton: {
    marginTop: 30,
    marginBottom: 20
  }
}
SearchAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeCheckbox: PropTypes.func,
  handleChangeInput: PropTypes.func,
  handleTrim: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
};
