import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "320px",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    margin: 10,
  },
  // UnitComponent
  singContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "640px",
    backgroundColor: "rgba(246, 249, 252, 0.5)",
    border: "2px solid rgba(158, 173, 187, 0.8)",
    borderRadius: 10,
  },
  sing: {
    borderBottom: "2px solid rgba(158, 173, 187, 0.8)",
  },
  textSing: {
    color: "#000000",
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 15,
  },
  saveButton: {
    paddingLeft: 70,
    paddingRight: 70,
    marginTop: 20,
    border: "1px solid #3050ff",
  },
  modalSingning: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    height: "220px",
    width: "300px",
    borderRadius: 10,
    borderWidth: 10,
    backgroundColor: "#ffffff",
  },
  titleModal: {
    color: "#000000",
    fontSize: 20,
    textAlign: "center",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
  },
  checkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  textCheck: {
    fontSize: 15,
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
  },
  buttonModal: {
    marginTop: 20,
  },
  modalErrorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    height: "220px",
    width: "300px",
    borderRadius: 10,
    backgroundColor: "#ffffff",
  },
  titleErrorModal: {
    color: "#000000",
    fontSize: 17,
    textAlign: "center",
    marginTop: 20,
    fontFamily: "Poppins-Regular",
  },
  textError: {
    fontSize: 14.5,
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    marginTop: 15,
    textAlign: 'center'
  },
  itemContainerWhatsApp: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#2BB741",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginTop: 20,
    borderRadius: 30,
  },
  image: {
    height: 25,
    marginRight: 15,
  },
  textWhats: {
    color: "#FFFFFF",
  }
}));

export default useStyles;
