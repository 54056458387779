import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFF",
    paddingTop: 50,
    paddingBottom: 30
  },
  // UnitComponent
  mainTitle: {
    fontSize: '18px',
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f9fc",
    border: '2px solid #3050ff',
    borderRadius: 15,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 30,
    paddingTop: 30,
    marginBottom: 200
  },
  formElement: {
    marginTop: 30,
    marginBottom: 20
  },
  hiperTextPrivacy: {
    color: "#3050ff",
    fontFamily: "Poppins",
    fontSize: '14px',
    marginBottom: 10
  },
  doubleParamForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default useStyles;
