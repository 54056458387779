import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "640px",
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  reviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  reviewProcess: {
    marginTop: 150,
  },
  title: {
    fontSize: 18,
    fontFamily: "Poppins",
    marginTop: 25,
    textAlign: "center",
  },
  timer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  textTimer: {
    display: "flex",
    flexDirection: "row",
  },
  numberText: {
    fontSize: 40,
    color: "#858d9c",
    fontFamily: "Poppins",
  },
  text: {
    fontSize: 13,
    color: "#858d9c",
    fontFamily: "Poppins",
    marginRight: 5,
  },
  text1: {
    fontSize: 13,
    color: "#858d9c",
    fontFamily: "Poppins",
    marginLeft: 5,
  },
}));

export default useStyles;
