import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { useNavigate, useParams } from "react-router";

import CongratulationsUI from "./Congratulations";
import useStyles from "./Congratulations.styles";

export function Congratulations() {
  const { phoneNumber, user_id } = useParams();
  const history = useNavigate();
  const classes = useStyles();

  const onContinueAction = () => {
    history(`/${phoneNumber}/${user_id}/create-password`, { replace: true });
  };

  return (
    <CongratulationsUI classes={classes} _onContinueAction={onContinueAction} />
  );
}

Congratulations.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default Congratulations;
