import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 20,
    marginTop: 50
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 30,
  },
  formElement: {
    marginBottom: 30,
  },
  doubleParamForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkboxLabel: {
    opacity: 0.7,
  },
  buttonStyle: {
    color: "red",
  },
  selectAdornment: {
    position: 'absolute',
    padding: 0,
    right: '0px',
  },
}));

export default useStyles;
