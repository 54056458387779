import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFFFFF",
    paddingBottom: 30,
  },
  // UnitComponent
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  backgroundImage: {
    width: '100%',
    height: '260px'
  },
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 30,
    fontSize: '18px'
  },
  subTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 30,
    color: "#2b2c49",
    fontSize: '13px'
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9fafb",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    borderRadius: 15,
  },
  itemContainerWhatsApp: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#3050ff",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    borderRadius: 30,
  },
  image: {
    height: 25,
    marginRight: 15,
  },
  textWhats: {
    color: "#FFFFFF",
  }
}));

export default useStyles;
