import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import AdvisorContactUI from "./AdvisorContact";
import useStyles from "./AdvisorContact.styles";
import {
  setMixPanelEvents,
  clearStateAdvisor,
} from "../../redux/actions/advisor.action";

export function AdvisorContact() {
  const history = useNavigate();
  const { phoneNumber, user_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearStateAdvisor());
    },
    []
  );

  const callAdvisor = () => {
    dispatch(setMixPanelEvents(user_id, "CALL_ADVISER"));
    window.location.href = process.env.REACT_APP_PHONE_TO_CALL;
    history(`/${phoneNumber}/${user_id}/download`, { replace: true });
  };

  const withPartner = () => {
    dispatch(setMixPanelEvents(user_id, "WITH_ADVISER"));
    history(`/${phoneNumber}/${user_id}/download`, { replace: true });
  };

  const onClickWhatsapp = () => {
    dispatch(setMixPanelEvents(user_id, "WHATSAPP_CHAT"));
    window.open(`${process.env.REACT_APP_WHATSAPP_LINK}&text=Mi%20préstamo%20fue%20pre%20aprobado`);
    history(`/${phoneNumber}/${user_id}/download`, { replace: true });
  };

  return (
    <AdvisorContactUI
      classes={classes}
      callAdvisor={callAdvisor}
      withPartner={withPartner}
      onClickWhatsapp={onClickWhatsapp}
    />
  );
}

export default AdvisorContact;
