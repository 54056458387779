import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

import { BoxLayout, Text } from "../../components";

export function Waiting({ classes }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <CircularProgress size={70} className={classes.spinner} />
        <Text
          className={classes.title}
          variant="h3"
          color="initial"
          aling="center"
        >
          Espera un momento estamos trabajando en su registro
        </Text>
      </BoxLayout>
    </BoxLayout>
  );
}

export default Waiting;

Waiting.propTypes = {
  classes: PropTypes.object.isRequired,
};
