import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  BoxLayout, Button, Modal, Text,
} from '../../components';
import images from '../../assets/images';
export function SingContract({
  classes, 
  metadata,
  viewerPDF, 
  attempts,
  isIdentity,
  isOpenModal
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <Text
          className={classes.title}
          variant="h5"
          color="initial"
          aling="justify"
        >
          Firma de contrato
        </Text>
        <Text
          className={classes.text}
          variant="h4"
          color="initial"
          aling="left"
        >
          A continuación, lee el contrato de crédito de YoFio y firma con el
          dedo.
        </Text>
        <BoxLayout className={classes.singContainer}>
          <img
            src={images.singIcon}
            className={classes.singIcon}
            alt="img-singIcon"
          />
          <Text
            className={classes.signText}
            variant="h4"
            color="initial"
            aling="left"
          >
            Firma aquí
          </Text>
          {attempts !== null && attempts <= 0 ? 
            <>
              <Text
                className={classes.textEndorsement}
                variant="h3"
                color="initial"
                aling="center"
              >
                ¡Agotaste los intentos de verificación!
              </Text>
              <button
                onClick={() =>( window.location.href = `${process.env.REACT_APP_WHATSAPP_LINK}&text=Se%20agotaron%20mis%20intentos%20de%20firma`)}
                className={classes.itemContainerWhatsApp}
              >
                <img className={classes.image} src={images.whatsappWhite} />
                <Text variant="h7" color="initial" align="justify" className={classes.textWhats}>
                  Contactar a soporte
                </Text>
              </button>
            </>:
            <>
              {isIdentity !== "" ?
                <>
                  <Helmet>
                    <script
                      src={process.env.REACT_APP_BUTTON_MATI}
                      type="text/javascript"
                    />
                  </Helmet>
                  <metamap-button
                    id="metamap-button"
                    clientId={process.env.REACT_APP_MATI_CLIENT_ID_REVERI}
                    flowId={process.env.REACT_APP_MATI_FLOW_ID_REVERI}
                    color="#505DED"
                    textcolor="#FFFFFF"
                    metadata={metadata}
                    identityId={isIdentity}
                  />
                </>:
                <>
                  <Helmet>
                    <script
                      src={process.env.REACT_APP_BUTTON_MATI}
                      type="text/javascript"
                    />
                  </Helmet>
                  <metamap-button
                    id="metamap-button"
                    clientId={process.env.REACT_APP_MATI_CLIENT_ID}
                    flowId={process.env.REACT_APP_MATI_FLOW_ID}
                    color="#505DED"
                    textcolor="#FFFFFF"
                    metadata={metadata}
                  />
                </>
              }
            </>
          }
        </BoxLayout>
        <Button
          newCustomStyle={styles.contract}
          variant="text"
          color="primary"
          onClick={() => viewerPDF()}
        >
          Ver Contrato YoFio
        </Button>
        <Modal
          open={isOpenModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalStyle}
        >
          <BoxLayout className={classes.modalContainer}>
            <Text
              className={classes.titleModal}
              variant="h5"
              color="initial"
            >
              La firma de su contrato se deberá hacer desde una nueva liga, actualice su app o contacte a su analista
            </Text>
          </BoxLayout>
        </Modal>
      </BoxLayout>
    </BoxLayout>
  );
}

export default SingContract;

const styles = {
  contract: {
    backgroundColor: '#ffffff',
    color: '#3050ff',
    marginTop: 20,
    border: '2px solid #3050ff',
  },
};

SingContract.propTypes = {
  classes: PropTypes.object.isRequired,
};
