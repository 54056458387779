import * as ACTION from "../actionTypes/contract";
import * as contractService from "../../services/contracts.service";

export const getContract = (user_type_id, user_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_USER_CONTRACT,
  });

  try {
    const contract = await contractService.getUserContract(
      user_type_id,
      user_id
    );
    if (contract) {
      dispatch({
        type: ACTION.RESPONSE_GET_USER_CONTRACT,
        result: contract,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_USER_CONTRACT,
      error,
    });
  }
};

export const clearGetContract = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_USER_CONTRACT,
  });
};

export const putContract =
  (user_type_id, user_id, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_PUT_USER_CONTRACT,
    });

    try {
      const contractSignature = await contractService.putUserContractSignature(
        user_type_id,
        user_id,
        body
      );
      if (contractSignature) {
        dispatch({
          type: ACTION.RESPONSE_PUT_USER_CONTRACT,
          result: contractSignature,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_PUT_USER_CONTRACT,
        error,
      });
    }
  };

export const clearPutContract = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_USER_CONTRACT,
  });
};

export const getMati =
  (verification_type, user_type_id, user_id, person_type) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_GET_USER_MATI,
    });

    try {
      const mati = await contractService.getUserMati(
        verification_type,
        user_type_id,
        user_id,
        person_type
      );
      if (mati) {
        dispatch({
          type: ACTION.RESPONSE_GET_USER_MATI,
          result: mati,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_GET_USER_MATI,
        error,
      });
    }
  };

export const clearGetMati = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_USER_MATI,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
