import React from "react";

import SomethingHappenedUI from "./SomethingHappened";
import useStyles from "./SomethingHappened.styles";

export function SomethingHappened() {
  const classes = useStyles();

  return <SomethingHappenedUI classes={classes} />;
}

export default SomethingHappened;
