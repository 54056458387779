import React from "react";

import SingningSuccessUI from "./SingningSuccess";
import useStyles from "./SingningSuccess.styles";

export function SingningSuccess() {
  const classes = useStyles();

  const success = () => {
    window.location.replace("https://mx.yofio.app/");
  };

  return <SingningSuccessUI classes={classes} success={success} />;
}

export default SingningSuccess;
