import client from "../client";

export const setMixPanelEvents = (userId, choice, userType = "SK") =>
  new Promise((resolve, reject) => {
    client
      .put(`/v1/users/${userType}/${userId}/adviser-contact-type/${choice}`)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });
