import React from 'react';
import PropTypes from 'prop-types';
import StepperUI from './Stepper';

function Stepper({ activeStep, activeBars, isEvaluation }) {
  return (
    <StepperUI activeStep={activeStep} activeBars={activeBars} isEvaluation={isEvaluation} />
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  activeBars: PropTypes.number,
  isEvaluation: PropTypes.bool,
};

Stepper.defaultProps = {
  activeBars: 0,
  isEvaluation: false,
};

export default Stepper;
