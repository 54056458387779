import * as ACTION from '../actionTypes/operations';

const operationsState = {
  loading: {
    POST_LEAD_ASSIGMENT: false,
  },
  error: {
    POST_LEAD_ASSIGMENT: false,
  },
  postLeadAssigmentData: {},
};

const operations = (state = operationsState, action) => {
  switch (action.type) {
    case ACTION.INIT_POST_LEAD_ASSIGMENT:
      return {
        ...state,
        loading: { ...state.loading, POST_LEAD_ASSIGMENT: true },
        error: { ...state.error, POST_LEAD_ASSIGMENT: null },
      };
    case ACTION.RESPONSE_POST_LEAD_ASSIGMENT:
      return {
        ...state,
        loading: { ...state.loading, POST_LEAD_ASSIGMENT: false },
        error: { ...state.error, POST_LEAD_ASSIGMENT: null },
        postLeadAssigmentData: action.result,
      };
    case ACTION.ERROR_POST_LEAD_ASSIGMENT:
      return {
        ...state,
        loading: { ...state.loading, POST_LEAD_ASSIGMENT: false },
        error: { ...state.error, POST_LEAD_ASSIGMENT: action.error },
        postLeadAssigmentData: {},
      };
    case ACTION.CLEAR_POST_LEAD_ASSIGMENT:
      return {
        ...state,
        loading: { ...state.loading, POST_LEAD_ASSIGMENT: false },
        error: { ...state.error, POST_LEAD_ASSIGMENT: null },
        postLeadAssigmentData: {},
      };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: operationsState.error };
    default:
      return state;
  }
};

export default operations;
