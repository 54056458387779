import React from "react";
import { useNavigate, useParams } from "react-router";

import ApprovedDocumentsUI from "./ApprovedDocuments";
import useStyles from "./ApprovedDocuments.styles";

export function ApprovedDocuments() {
  const history = useNavigate();
  const { user_id, user_type_id, person_type } = useParams();
  const classes = useStyles();

  const singningContract = () => {
    history(`/${user_type_id}/${user_id}/${person_type}/singning`, { replace: true });
  };
  return (
    <ApprovedDocumentsUI
      classes={classes}
      singningContract={singningContract}
    />
  );
}

export default ApprovedDocuments;
