/* eslint-disable indent */
import * as ACTION from "../actionTypes/catalogs";
import * as catalogsService from "../../services/catalogs.service";

export const getCities = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_CITIES,
  });

  try {
    const cities = await catalogsService.getCities();
    if (cities) {
      dispatch({
        type: ACTION.RESPONSE_GET_CITIES,
        result: cities,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_CITIES,
      error,
    });
  }
};

export const getBusinessTypes = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_BUSSINESS_TYPE,
  });

  try {
    const businessTypes = await catalogsService.getBusinessTypes();
    if (businessTypes) {
      dispatch({
        type: ACTION.RESPONSE_GET_BUSSINESS_TYPE,
        result: businessTypes,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_BUSSINESS_TYPE,
      error,
    });
  }
};

export const getZipCode = (zipCode) => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_ZIP_CODE,
  });

  dispatch({
    type: ACTION.INIT_GET_ZIP_CODE,
  });

  try {
    const zipCodeResponse = await catalogsService.getZipCode(zipCode);
    if (zipCodeResponse) {
      dispatch({
        type: ACTION.RESPONSE_GET_ZIP_CODE,
        result: zipCodeResponse,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_ZIP_CODE,
      error,
    });
  }
};

export const clearGetZipCode = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_ZIP_CODE,
  });
};

export const getSalesRange = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_SALES_RANGE,
  });

  try {
    const salesRange = await catalogsService.getSalesRange();
    if (salesRange) {
      dispatch({
        type: ACTION.RESPONSE_GET_SALES_RANGE,
        result: salesRange,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_SALES_RANGE,
      error,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
