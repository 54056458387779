// VerifyAvalPhone.js

import React from 'react';
import PropTypes from 'prop-types';
import { BoxLayout, Button, Text } from '../../components';
import Images from '../../assets/images';

export function VerifyAvalPhone({
  classes,
  collateralName,
  shopkeeperName,
  onAcceptConsultation,
  validationResult,
}) {
  // Steps 1 and 2: Display initial text and accept button
  // These steps can be omitted if necessary
  // BEGIN Steps 1 and 2
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '100px',
          }}
        >
          <img
            className={classes.yofioImage}
            src={Images.yofio_black}
            alt="img-yofio"
          />
        </div>
        {(validationResult === 'success') && (
          // Step 5: Display success message after validation
          <BoxLayout className={classes.page}>
            <Text
              className={classes.mainTitle}
              variant="h2"
              color="initial"
              align="justify"
            >
              ¡Excelente!
            </Text>
            <Text
              className={classes.subTitle}
              variant="h4"
              color="initial"
              align="justify"
            >
              El n&uacute;mero de tel&eacute;fono ha sido validado
              correctamente. Ser&aacute; contactado telef&oacute;nicamente para
              indicarle los pasos a seguir.
            </Text>
          </BoxLayout>
        )}
        {(validationResult === 'error') && (
          // Step 5: Display success message after validation
          <BoxLayout className={classes.page}>
            <Text
              className={classes.mainTitle}
              variant="h2"
              color="initial"
              align="justify"
            >
              ¡Lo sentimos!
            </Text>
            <Text
              className={classes.subTitle}
              variant="h4"
              color="initial"
              align="justify"
            >
              El n&uacute;mero de tel&eacute;fono ha podido ser
              validado correctamente. Por favor comun&iacute;quese con soporte
              t&eacute;cnico.
            </Text>
          </BoxLayout>
        )}
        {(!validationResult) && (
          <>
            <Text
              className={classes.mainTitle}
              variant="h2"
              color="initial"
              align="justify"
            >
              ¡Hola&nbsp;
              {collateralName}
              !
            </Text>
            <Text
              className={classes.subTitle}
              variant="h4"
              color="initial"
              align="justify"
            >
              Tu conocido&nbsp;
              {shopkeeperName}
              &nbsp;tiene una solicitud de crédito con YoFio y te ha
              seleccionado
              como aval. Para continuar con su solicitud por favor acepta la
              consulta crediticia a favor FRESH LENDING SAPI DE CV.
            </Text>
            <div style={{ height: '25px' }} />
            <Button
              onClick={onAcceptConsultation}
              className={classes.continueButton}
              variant="contained"
              color="primary"
            >
              Aceptar Consulta
            </Button>
          </>
        )}
      </BoxLayout>
    </BoxLayout>
  );
  // END Steps 1 and 2
}

VerifyAvalPhone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  collateralName: PropTypes.string,
  shopkeeperName: PropTypes.string,
  onAcceptConsultation: PropTypes.func.isRequired,
  validationResult: PropTypes.string,
};

VerifyAvalPhone.defaultProps = {
  collateralName: '',
  shopkeeperName: '',
  validationResult: '',
};

export default VerifyAvalPhone;
