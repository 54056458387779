import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router";

import SingningUI from "./Singning";
import useStyles from "./Singning.styles";
import {
  putContract,
  clearPutContract,
} from "../../redux/actions/contract.action";

export function Singning() {
  const history = useNavigate();
  const { user_id, user_type_id, person_type } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const sing = useRef({});
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [accept, setAccept] = React.useState(false);
  const [metaData, setMetaData] = useState("");
  const [browser, setBrowser] = useState("");

  const { userContractSignature, error } = useSelector(
    (state) => state.contractReducer
  );

  const handleChangeCheckbox = (event) => {
    setAccept(event.target.checked);
  };

  const navegador = () => {
    const agent = navigator.userAgent;
    const navegadores = [
      "Chrome",
      "Firefox",
      "Safari",
      "Opera",
      "Trident",
      "MSIE",
      "Edge",
    ];

    for (const i in navegadores) {
      if (agent.indexOf(navegadores[i]) !== -1) return navegadores[i];
    }
  };

  const getMetaData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setMetaData(res.data);
  };

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    if (Object.keys(userContractSignature).length !== 0) {
      dispatch(clearPutContract());
      setIsVisible(false);
      history("/singning-success", { replace: true });
    }
  }, [userContractSignature]);

  useEffect(() => {
    if (Object.keys(error).length !== 0 && 
      error.USER_CONTRACT_SIGNATURE !== null && 
      error.USER_CONTRACT_SIGNATURE !== undefined
    ) {
      if(error.USER_CONTRACT_SIGNATURE.response.data.message == "user not in progress") {
        setIsVisible(false);
        setIsError(true)
      }
    }
  }, [error.USER_CONTRACT_SIGNATURE]);

  const resetSing = () => {
    sing.current.clear();
  };

  const saveSing = () => {
    const tmpSignature = sing.current.getTrimmedCanvas().toDataURL().split(",");
    const body = {
      person_type,
      signature: tmpSignature[1],
      metadata: JSON.stringify(metaData),
    };
    dispatch(putContract(user_type_id, user_id, body));
  };

  const onClickWhatsapp = () => {
    window.open(process.env.REACT_APP_WHATSAPP_LINK);
  };

  return (
    <SingningUI
      classes={classes}
      resetSing={resetSing}
      saveSing={saveSing}
      sing={sing}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      accept={accept}
      handleChangeCheckbox={handleChangeCheckbox}
      isError={isError}
      setIsError={setIsError}
      onClickWhatsapp={onClickWhatsapp}
    />
  );
}

export default Singning;
