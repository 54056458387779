/* eslint-disable indent */
import * as ACTION from '../actionTypes/operations';
import * as operationsService from '../../services/operations.service';

export const postLeadAssigment = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_LEAD_ASSIGMENT,
  });

  try {
    const leadAssigmentData = await operationsService.postAssignLead(body);
    if (leadAssigmentData) {
      dispatch({
        type: ACTION.RESPONSE_POST_LEAD_ASSIGMENT,
        result: leadAssigmentData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_LEAD_ASSIGMENT,
      error,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
