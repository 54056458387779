import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    // height: '100%',
    backgroundColor: "#FFF",
  },
  // UnitComponent
  mainTitle: {
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 20,
    marginTop: 50
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 20,
    paddingLeft: 20,
  },
  formElement: {
    marginBottom: 30,
  },
  specialFormElement: {
    marginBottom: 30,
    zIndex: 2,
  },
  selectAdornment: {
    position: 'absolute',
    padding: 0,
    right: '0px',
  },
  bdDay: {
    marginBottom: 30,
    '& [role="button"]': {
      minWidth: '35px',
    },
  },
  bdMonth: {
    marginBottom: 30,
    '& [role="button"]': {
      minWidth: '93px',
    },
  },
  bdYear: {
    marginBottom: 30,
    '& [role="button"]': {
      minWidth: '55px',
    },
  },
}));

export default useStyles;
