import { v4 as uuidv4 } from "uuid";

export const getRndArrayFromString = (str) => {
    const res = [0x67];
    for (var i = 0; i < Math.min(str.length, 16); i++) {
        const d = parseInt(str.charAt(i))
        if (Number.isNaN(d)) {
            continue;
        }
        res.push(d);
    }
    for (var i = res.length; i < 16; i++) {
        res.push(0x1c);
    }
    return {
        random: res
    };
};

export const getUuidFromPhoneNumber = (lada, phone) => {
    const rnd = getRndArrayFromString(lada + phone);
    return uuidv4(rnd);
};