import React from 'react';
import PropTypes from 'prop-types';
import RemoveRedEyeOutlined from '@material-ui/icons/RemoveRedEyeOutlined';

import {
  BoxLayout, Button, Text, CodeInput,
} from '../../components';
import Stepper from '../../components/Stepper';

export function CreatePassword({
  classes,
  _handleChangePassword,
  _handleChangeConfirmPassword,
  isValidatePassword,
  isValidateConfirmPassword,
  errorPassword,
  errorConfirmPassword,
  _onContinueAction,
  setShowPassword,
  showPassword,
  setShowValidatePassword,
  showValidatePassword,
  password,
  confirmPassword,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <Stepper activeBars={5} activeStep={1}/>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          ¡Listo, ahora solo necesitas
          {' '}
          <b>crear tu contraseña</b>
          !
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Esta te permitirá ingresar a la app y concluir los pasos finales de tu solicitud.
        </Text>
        <BoxLayout className={classes.bodyContainerPassword}>
          <Text
            className={classes.labelNip}
            variant="h5"
            color="initial"
            align="justify"
          >
            Crea tu contraseña de 6 dígitos
          </Text>
          <BoxLayout className={classes.passwordContainer}>
            <CodeInput
              length={6}
              onChange={_handleChangePassword}
              type="numeric"
              inputMode="numeric"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: 10,
              }}
              inputStyle={{
                borderColor: 'gray',
                width: '20%',
                height: '40px',
                marginTop: 10,
                borderRadius: 5,
              }}
              inputFocusStyle={{ borderColor: 'blue' }}
              autoSelect
              secret={!showPassword}
            />
            <RemoveRedEyeOutlined
              onClick={() => setShowPassword(!showPassword)}
              style={{ color: '#3049ff' }}
            />
          </BoxLayout>
          {errorPassword !== '' && (
            <Text
              className={classes.labelErrorNip}
              variant="h5"
              color="initial"
              align="justify"
            >
              {errorPassword}
            </Text>
          )}
        </BoxLayout>
        <BoxLayout className={classes.bodyContainerValidatePassword}>
          <Text
            className={classes.labelNip}
            variant="h5"
            color="initial"
            align="justify"
          >
            Confirma tu contraseña
          </Text>
          <BoxLayout className={classes.passwordContainer}>
            <CodeInput
              length={6}
              onChange={_handleChangeConfirmPassword}
              type="numeric"
              inputMode="numeric"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: 10,
              }}
              inputStyle={{
                borderColor: 'gray',
                width: '20%',
                height: '40px',
                marginTop: 10,
                borderRadius: 5,
              }}
              inputFocusStyle={{ borderColor: 'blue' }}
              autoSelect
              secret={!showValidatePassword}
            />
            <RemoveRedEyeOutlined
              onClick={() => setShowValidatePassword(!showValidatePassword)}
              style={{ color: '#3049ff' }}
            />
          </BoxLayout>
          {errorConfirmPassword !== '' && (
            <Text
              className={classes.labelErrorNip}
              variant="h5"
              color="initial"
              align="justify"
            >
              {errorConfirmPassword}
            </Text>
          )}
        </BoxLayout>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          isDisabled={
            !(
              isValidatePassword
              && isValidateConfirmPassword
              && password === confirmPassword
            )
          }
          onClick={() => _onContinueAction()}
        >
          Continuar
        </Button>
        <Text
          className={classes.footer}
          color="initial"
          align="center"
        >
          Recuerda que tu contraseña debe ser única y no la debes compartir con ninguna persona.

        </Text>
      </BoxLayout>
    </BoxLayout>
  );
}

export default CreatePassword;

CreatePassword.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  _handleChangePassword: PropTypes.func,
  _handleChangeConfirmPassword: PropTypes.func,
  isValidatePassword: PropTypes.bool,
  isValidateConfirmPassword: PropTypes.bool,
  errorPassword: PropTypes.string,
  errorConfirmPassword: PropTypes.string,
  _onContinueAction: PropTypes.func,
  setShowPassword: PropTypes.func,
  showPassword: PropTypes.bool,
  setShowValidatePassword: PropTypes.func,
  showValidatePassword: PropTypes.bool,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
};

CreatePassword.defaultProps = {
  classes: {},
  _handleChangePassword: () => {},
  _handleChangeConfirmPassword: () => {},
  isValidatePassword: false,
  isValidateConfirmPassword: false,
  errorPassword: '',
  errorConfirmPassword: '',
  _onContinueAction: () => {},
  setShowPassword: () => {},
  showPassword: false,
  setShowValidatePassword: () => {},
  showValidatePassword: false,
  password: '',
  confirmPassword: '',
};
