export default {
  image404: require("./404-image.jpg"),
  mainBackground: require("./mainBackground.png"),
  yofio: require("./yofio.png"),
  success: require("./success.png"),
  reject: require("./reject.png"),
  download: require("./download.png"),
  yofio_black: require("./black_logo.png"),
  location: require("./location.png"),
  programacalendario: require("./programacalendario.png"),
  whatsapp: require("./whatsapp.png"),
  whatsappWhite: require('./whatsappWhite.png'),
  asesor: require("./asesor.png"),
  llamarasesor: require("./llamarasesor.png"),
  tooltip: require("./tooltip2.png"),
  singIcon: require("./singningIcon.png"),
  singProcess: require("./singningProcess.png"),
  error: require("./Error.png"),
  exito: require("./Exito.png"),
  pausado: require("./Pausado.png"),
  approvedLoan: require('./ApprovedLoan.png'),
  evaluationIcon: require('./evaluationIcon.png'),
  registerIcon: require('./registerIcon.png'),
};
