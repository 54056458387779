import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    backgroundColor: '#FFFFFF',
    paddingBottom: 30,
  },
  header: {
    display: 'flex',
    color: '#2B2C49',
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    width: '320px',
  },
  body: {
    marginTop: '10px',
    display: 'flex',
    color: '#2B2C49',
    fontWeight: 'lighter',
    fontSize: '14px',
    textAlign: 'center',
    width: '320px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#3050ff',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    padding: 10,
    marginRight: 20,
    marginTop: 20,
    borderRadius: 30,
  },
  buttonText: {
    color: 'white',
    fontWeight: 500,
    fontSize: '16px',
  },
}));

export default useStyles;
