import React from "react";
import PropTypes from "prop-types";

import {
  BoxLayout,
  Button,
  Text,
  CodeInput,
  SuccessMessage,
} from "../../components";

export function SendSMS({
  classes,
  _handleChangeNip,
  errorNip,
  _onContinueNipAction,
  isValidateNip,
  phoneNumber,
  defaultNip,
  resendNip,
  showMessageSMS,
  onCloseShowMessageSMS,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.autoCompleteBar}>
          <Text
            className={classes.stepMark}
            variant="h3"
            color="initial"
            align="justify"
          >
            Paso 6 de 6
          </Text>
        </div>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          NIP de Consulta 
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Escribe el número de solicitud (NIP) que te enviamos al:&nbsp;
          <a href={`tel:${phoneNumber}`} className={classes.hiperTextPrivacy}>
            {phoneNumber}
          </a>
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Al ingresar el número de solicitud (NIP) que te enviamos, autorizas la
          consulta de historial crediticio a favor FRESH LENDING SAPI DE CV
        </Text>
        <BoxLayout className={classes.bodyContainer}>
          <Text
            className={classes.labelNip}
            variant="h5"
            color="initial"
            align="justify"
          >
            NIP de consulta
          </Text>
          {defaultNip !== "" ? (
            <CodeInput
              initialValue={defaultNip}
              length={4}
              onChange={_handleChangeNip}
              type="numeric"
              inputMode="number"
              style={{ display: "flex", justifyContent: "center" }}
              inputStyle={{
                borderColor: "#4a4b4b",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "25%",
                height: "40px",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              autoSelect
            />
          ) : (
            <CodeInput
              length={4}
              onChange={_handleChangeNip}
              type="numeric"
              inputMode="number"
              style={{ display: "flex", justifyContent: "center" }}
              inputStyle={{
                borderColor: "#4a4b4b",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "25%",
                height: "40px",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              autoSelect
            />
          )}
          {errorNip !== "" && (
            <Text
              className={classes.labelErrorNip}
              variant="h5"
              color="initial"
              align="justify"
            >
              {errorNip}
            </Text>
          )}
        </BoxLayout>
        <Button
          newCustomStyle={styles.resendNipButton}
          variant="text"
          color="primary"
          onClick={() => resendNip()}
        >
          Reenviar Código
        </Button>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          isDisabled={!isValidateNip}
          onClick={() => _onContinueNipAction()}
        >
          Continuar
        </Button>
      </BoxLayout>
      {showMessageSMS && (
        <SuccessMessage
          message="Se ha enviado el nip nuevamente"
          onClose={() => onCloseShowMessageSMS(false)}
        />
      )}
    </BoxLayout>
  );
}

export default SendSMS;

const styles = {
  buttonStyle: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
  },
  resendNipButton: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
    marginTop: 10,
    marginRight: 190,
    padding: 0,
    borderRadius: 0,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 150,
  },
};

SendSMS.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  _handleChangeNip: PropTypes.func,
  errorNip: PropTypes.string,
  _onContinueNipAction: PropTypes.func,
  isValidateNip: PropTypes.bool,
  phoneNumber: PropTypes.string,
  defaultNip: PropTypes.string,
  resendNip: PropTypes.func,
  showMessageSMS: PropTypes.bool,
  onCloseShowMessageSMS: PropTypes.func,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  handleChangeInputLada: PropTypes.func,
  lada: PropTypes.string,
  newPhoneNumber: PropTypes.string,
  handleChangeNewPhone: PropTypes.func,
  _newNumber: PropTypes.func,
};

SendSMS.defaultProps = {
  _handleChangeNip: () => {},
  errorNip: "",
  _onContinueNipAction: () => {},
  isValidateNip: false,
  phoneNumber: "",
  defaultNip: "",
  resendNip: () => {},
  showMessageSMS: false,
  onCloseShowMessageSMS: () => {},
  handleChangeInputLada: () => {},
  lada: "",
  newPhoneNumber: "",
  handleChangeNewPhone: () => {},
  _newNumber: () => {},
};
