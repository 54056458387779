import React from "react";
import PropTypes from "prop-types";
import ButtonM from "@material-ui/core/Button";

function Button({
  children,
  isDisabled,
  customStyle,
  newButtonStyle,
  ...others
}) {
  return (
    <ButtonM
      style={{ ...customStyle, newButtonStyle, fontFamily: "Poppins" }}
      disabled={isDisabled}
      {...others}
    >
      {children}
    </ButtonM>
  );
}

export default Button;

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isDisabled: PropTypes.bool,
  customStyle: PropTypes.object,
  newButtonStyle: PropTypes.object,
};
