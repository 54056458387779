import client from "../client";

export const getCities = () =>
  new Promise((resolve, reject) => {
    client.get("/v1/cities?only-allowed=true").then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getZipCode = (zipCode) => {
  const path = `/v1/zip-codes/${zipCode}`;
  return new Promise((resolve, reject) => {
    client.get(path).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getBusinessTypes = () =>
  new Promise((resolve, reject) => {
    client.get("/v1/business-types").then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });

  export const getSalesRange = () => {
    const path = `/v1/sales-ranges`;
    return new Promise((resolve, reject) => {
      client.get(path).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };