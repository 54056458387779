import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "640px",
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  processContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorDocuments: {
    marginTop: 70,
  },
  title: {
    fontSize: 20,
    fontFamily: "Poppins",
    marginTop: 25,
  },
  text: {
    textAlign: "center",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    fontFamily: "Poppins",
    fontSize: 15,
  },
  instructions: {
    marginTop: 20,
  },
  information: {
    textAlign: "left",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    fontSize: 13,
    fontFamily: "Poppins",
  },
  button: {
    marginTop: 70,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    borderRadius: 10,
    backgroundColor: "#FFF",
    padding: 10
  },
  titleEndorsement: {
    color: "#000",
    fontWeight: "bold",
    marginTop: 5
  },
  textEndorsement: {
    color: "#4a4b4b",
    fontWeight: "normal",
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  itemContainerWhatsApp: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "green",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 10,
    borderRadius: 30,
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 15,
  },
  textWhats: {
    color: "#FFF",
  }
}));

export default useStyles;
