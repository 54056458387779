import axios from "axios";

import * as Sentry from "@sentry/react";
import { appService } from "./services/app.service";

const client = (() =>
  axios.create({
    baseURL: appService.config.paths.api.basePath,
    timeout: 150000,
    headers: {
      "Content-Type": "application/json",
    },
  }))();

const excludedTraceRequest = [
  /^get\|404\|\/?users(\?.*)?$/,
  /^post\|409\|\/?users$/,
  /^post\|422\|\/?users$/,
  /^put\|422\|\/?users\/.*\/.*\/nip$/,
  /^post\|412\|\/?users\/.*\/.*\/addresses$/,
  /^put\|412\|\/?users\/.*\/.*\/businesses$/,
  /^put\|422\|\/?users\/.*\/.*\/businesses$/,
  /^put\|422\|\/?users\/.*\/.*\/aval\/nip$/,
];

const isExcludedTraceRequest = function (method, status, path) {
  const key = `${method}|${status}|${path}`;
  for (const re of excludedTraceRequest) {
    if (re.test(key)) {
      return true;
    }
  }
  return false;
};

client.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("clientToken");
  if (token) {
    config.headers.Authorization = token;
  } else {
    config.headers.Authorization = null;
  }
  console.log(
    "%c REQUEST: %s",
    "color: #0000FF; font-weight: bold",
    JSON.stringify(config)
  );
  return config;
});

// Intercept all responses
client.interceptors.response.use(
  (response) => {
    console.log(
      `%c ${response.status} - ${response.config}:`,
      "color: #008000; font-weight: bold",
      response
    );
    return response;
  },

  (error) => {
    if (error.response) {
      const { config } = error.response;
      console.log(
        `%c ${error.response.status} - ${JSON.stringify(config)}:`,
        "color: #a71d5d; font-weight: bold",
        error.response
      );
      const ctx = {
        config,
        data: error.response.data,
      };
      if (
        error.response.status >= 400 &&
        error.response.status < 500 &&
        !isExcludedTraceRequest(
          config.method,
          error.response.status,
          config.url
        )
      ) {
        Sentry.captureException(error, { level: "warning", contexts: ctx });
      } else if (error.response.status >= 500) {
        Sentry.captureException(error, { contexts: ctx });
      }
    }

    return Promise.reject(error);
  }
);

export default client;
