export const INIT_GET_USER_CONTRACT = "CONTRACT::INIT_GET_USER_CONTRACT";
export const RESPONSE_GET_USER_CONTRACT =
  "CONTRACT::RESPONSE_GET_USER_CONTRACT";
export const ERROR_GET_USER_CONTRACT = "CONTRACT::ERROR_GET_USER_CONTRACT";
export const CLEAR_GET_USER_CONTRACT = "CONTRACT::CLEAR_GET_USER_CONTRACT";

export const INIT_PUT_USER_CONTRACT = "CONTRACT::INIT_PUT_USER_CONTRACT";
export const RESPONSE_PUT_USER_CONTRACT =
  "CONTRACT::RESPONSE_PUT_USER_CONTRACT";
export const ERROR_PUT_USER_CONTRACT = "CONTRACT::ERROR_PUT_USER_CONTRACT";
export const CLEAR_PUT_USER_CONTRACT = "CONTRACT::CLEAR_PUT_USER_CONTRACT";

export const INIT_GET_USER_MATI = "CONTRACT::INIT_GET_USER_MATI";
export const RESPONSE_GET_USER_MATI = "CONTRACT::RESPONSE_GET_USER_MATI";
export const ERROR_GET_USER_MATI = "CONTRACT::ERROR_GET_USER_MATI";
export const CLEAR_GET_USER_MATI = "CONTRACT::CLEAR_GET_USER_MATI";

export const CLEAR_ERRORS = "CONTRACT:CLEAR_ERRORS";
