// index.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../hooks';
import VerifyAvalPhoneUI from './VerifyAvalPhone';
import useStyles from '../SendSMSPartner/SendSMSPartner.styles';
import {
  sendAvalReverseOtp,
  validateAvalReverseOtp,
} from '../../redux/actions/collateral.action';

function VerifyAvalPhoneContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Get query parameters
  const query = useQuery();
  const sessionUuid = query.get('sessionUuid');
  const accountId = query.get('user_id');
  const accountTypeId = query.get('user_type_id');
  const collateralId = query.get('collateral_id');
  const shopkeeperName = query.get('shopkeeper_name');
  const collateralName = query.get('collateral_name');

  // Select necessary state from Redux store
  const {
    redirectionUrlObject,
    validationResult,
  } = useSelector(
    (state) => state.collateralReducer,
  );

  // Steps 1 and 2: Handle initial accept consultation
  // These steps can be omitted if necessary
  // BEGIN Steps 1 and 2
  useEffect(() => {
    // If sessionUuid is not present, proceed with steps 1 and 2
    if (sessionUuid) {
      dispatch(validateAvalReverseOtp(sessionUuid));
    }
  }, []);

  useEffect(() => {
    console.log('***** validationResult', validationResult);
  }, [validationResult]);

  useEffect(() => {
    // Listen for changes in redirectionUrlObject to redirect
    const { redirection_url } = redirectionUrlObject || {};
    if (redirection_url) {
      window.location.href = redirection_url;
    }
  }, [redirectionUrlObject]);

  const handleAcceptConsultation = () => {
    // Dispatch action to get redirection URL
    dispatch(sendAvalReverseOtp(accountTypeId, accountId, collateralId));
  };
  // END Steps 1 and 2

  return (
    <VerifyAvalPhoneUI
      classes={classes}
      collateralName={collateralName}
      shopkeeperName={shopkeeperName}
      onAcceptConsultation={handleAcceptConsultation}
      validationResult={validationResult}
    />
  );
}

export default VerifyAvalPhoneContainer;
