import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "320px",
    height: "640px",
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  spinner: {
    color: "#3050ff",
  },
  title: {
    fontSize: 16,
    fontFamily: "Poppins",
    marginTop: 50,
    textAlign: "center",
  },
}));

export default useStyles;
