import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';

import App from './containers/App';

import HomePage from './containers/Home';
import AboutYouForm from './containers/AboutYouForm';
import AdditionalData from './containers/AdditionalData';
import SearchAddress from './containers/SearchAddress';
import WheresLivingForm from './containers/WheresLivingForm';
import TellMoreAbout from './containers/TellMoreAbout';
import NotFoundPage from './containers/NotFound';
import SendSMS from './containers/SendSMS';
import Congratulations from './containers/Congratulations';
import Rejected from './containers/Rejected';
import CreatePassword from './containers/CreatePassword';
import Download from './containers/Download';
import SendSMSPartner from './containers/SendSMSPartner';
import CongratulationsPartner from './containers/CongratulationsPartner';
import RejectedByLocation from './containers/RejectedByLocation';
import AdvisorContact from './containers/AdvisorContact';
import SingContract from './containers/SingContract';
import Singning from './containers/Singning';
import SingningSuccess from './containers/SingningSuccess';
import ReviewDocuments from './containers/ReviewDocuments';
import ApprovedDocuments from './containers/ApprovedDocuments';
import ErrorDocuments from './containers/ErrorDocuments';
import ManualVerification from './containers/ManualVerification';
import SomethingHappened from './containers/SomethingHappened';
import Waiting from './containers/Waiting';
import WelcomeVideo from './containers/WelcomeVideo';
import VerifyAvalPhone from './containers/VerifyAvalPhone';

function Routes() {
  return (
    <Router>
      <App>
        <Switch>
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/home/:phoneNumber" element={<HomePage />} />
          <Route
            exact
            path="/:phoneNumber/about-you"
            element={<AboutYouForm />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/additional-data"
            element={<AdditionalData />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/about-living"
            element={<WheresLivingForm />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/search-address"
            element={<SearchAddress />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/more-about"
            element={<TellMoreAbout />}
          />
          <Route exact path="/:user_id/waiting" element={<Waiting />} />
          <Route
            exact
            path="/:phoneNumber/:user_id/send-nip"
            element={<SendSMS />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/congratulations"
            element={<Congratulations />}
          />
          <Route exact path="/rejected" element={<Rejected />} />
          <Route
            exact
            path="/rejected-location"
            element={<RejectedByLocation />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/create-password"
            element={<CreatePassword />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/download"
            element={<Download />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/advisor-contact"
            element={<AdvisorContact />}
          />
          <Route
            exact
            path="/:phoneNumber/:user_id/advisor-welcome"
            element={<WelcomeVideo />}
          />
          {/* Sing Contract Landing */}
          <Route path="/send-contract-sign" element={<SingContract />} />
          <Route
            exact
            path="/:user_type_id/:user_id/:person_type/singning"
            element={<Singning />}
          />
          <Route exact path="/singning-success" element={<SingningSuccess />} />
          <Route
            exact
            path="/:user_type_id/:user_id/:titular_full_name/:person_type/:collateral_full_name/review-documents"
            element={<ReviewDocuments />}
          />
          <Route
            exact
            path="/:user_type_id/:user_id/:titular_full_name/:person_type/:collateral_full_name/approved-documents"
            element={<ApprovedDocuments />}
          />
          <Route
            exact
            path="/:user_type_id/:user_id/:titular_full_name/:person_type/:collateral_full_name/error-documents"
            element={<ErrorDocuments />}
          />
          <Route
            exact
            path="/:user_type_id/:user_id/:titular_full_name/:person_type/:collateral_full_name/manual-verification"
            element={<ManualVerification />}
          />
          <Route
            exact
            path="/something-happened"
            element={<SomethingHappened />}
          />
          {/* Partner Landing */}
          <Route exact path="/send-nip-partner" element={<SendSMSPartner />} />
          <Route exact path="/verify-aval-phone"
                 element={<VerifyAvalPhone />} />
          <Route
            exact
            path="/:to/:user_type_id/:user_id/:phoneNumber/send-nip-partner"
            element={<SendSMSPartner />}
          />
          <Route
            exact
            path="/congratulations-partner"
            element={<CongratulationsPartner />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Switch>
      </App>
    </Router>
  );
}

export default Routes;
