import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";

function SuccessMessage({ classes, message, onClose }) {
  return (
    <SnackbarContent
      className={classes.snackbarContainer}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.messageContainer}>
          <CheckCircleIcon className={classes.iconLeft} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}

export default SuccessMessage;

SuccessMessage.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

SuccessMessage.defaultProps = {
  classes: {},
  message: "",
  onClose: () => {},
};
