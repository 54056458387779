export const INIT_GET_USER_DATA = "ORIGINATION::INIT_GET_USER_DATA";
export const RESPONSE_GET_USER_DATA = "ORIGINATION::RESPONSE_GET_USER_DATA";
export const ERROR_GET_USER_DATA = "ORIGINATION::ERROR_GET_USER_DATA";
export const CLEAR_GET_USER_DATA = "ORIGINATION::CLEAR_GET_USER_DATA";
export const NEW_USER = "ORIGINATION::NEW_USER";

export const INIT_GET_USER_DATA_DETAIL =
  "ORIGINATION::INIT_GET_USER_DATA_DETAIL";
export const RESPONSE_GET_USER_DATA_DETAIL =
  "ORIGINATION::RESPONSE_GET_USER_DATA_DETAIL";
export const ERROR_GET_USER_DATA_DETAIL =
  "ORIGINATION::ERROR_GET_USER_DATA_DETAIL";
export const CLEAR_GET_USER_DATA_DETAIL =
  "ORIGINATION::CLEAR_GET_USER_DATA_DETAIL";

export const INIT_POST_USER = "ORIGINATION::INIT_POST_USER";
export const RESPONSE_POST_USER = "ORIGINATION::RESPONSE_POST_USER";
export const ERROR_POST_USER = "ORIGINATION::ERROR_POST_USER";
export const CLEAR_POST_USER = "ORIGINATION::CLEAR_POST_USER";

export const INIT_SEND_CODE = "ORIGINATION::INIT_SEND_CODE";
export const RESPONSE_CODE_DATA = "ORIGINATION::RESPONSE_CODE_DATA";
export const ERROR_CODE_DATA = "ORIGINATION::ERROR_CODE_DATA";

export const INIT_CODE_CONFIRMATION = "ORIGINATION::INIT_CODE_CONFIRMATION";
export const RESPONSE_CODE_CONFIRMATION =
  "ORIGINATION::RESPONSE_CODE_CONFIRMATION";
export const ERROR_CODE_CONFIRMATION = "ORIGINATION::ERROR_CODE_CONFIRMATION";

export const INIT_SEND_NIP = "ORIGINATION::INIT_SEND_NIP";
export const RESPONSE_NIP_DATA = "ORIGINATION::RESPONSE_NIP_DATA";
export const ERROR_NIP_DATA = "ORIGINATION::ERROR_NIP_DATA";
export const CLEAR_SEND_NIP_DATA = "ORIGINATION::CLEAR_SEND_NIP_DATA";

export const INIT_RESEND_NIP = "ORIGINATION::INIT_RESEND_NIP";
export const RESPONSE_RESEND_NIP_DATA = "ORIGINATION::RESPONSE_RESEND_NIP_DATA";
export const ERROR_RESEND_NIP_DATA = "ORIGINATION::ERROR_RESEND_NIP_DATA";
export const CLEAR_RESEND_NIP_DATA = "ORIGINATION::CLEAR_RESEND_NIP_DATA";

export const INIT_NIP_CONFIRMATION = "ORIGINATION::INIT_NIP_CONFIRMATION";
export const RESPONSE_NIP_CONFIRMATION =
  "ORIGINATION::RESPONSE_NIP_CONFIRMATION";
export const ERROR_NIP_CONFIRMATION = "ORIGINATION::ERROR_NIP_CONFIRMATION";

export const INIT_PWD_CREATION = "ORIGINATION::INIT_PWD_CREATION";
export const RESPONSE_PWD_CREATION = "ORIGINATION::RESPONSE_PWD_CREATION";
export const ERROR_PWD_CREATION = "ORIGINATION::ERROR_PWD_CREATION";

export const INIT_POST_ADDRESS = "ORIGINATION::INIT_POST_ADDRESS";
export const RESPONSE_POST_ADDRESS = "ORIGINATION::RESPONSE_POST_ADDRESS";
export const ERROR_POST_ADDRESS = "ORIGINATION::ERROR_POST_ADDRESS";
export const CLEAR_POST_ADDRESS = "ORIGINATION::CLEAR_POST_ADDRESS";

export const INIT_PUT_BUSINESS = "ORIGINATION::INIT_PUT_BUSINESS";
export const RESPONSE_PUT_BUSINESS = "ORIGINATION::RESPONSE_PUT_BUSINESS";
export const ERROR_PUT_BUSINESS = "ORIGINATION::ERROR_PUT_BUSINESS";
export const CLEAR_PUT_BUSINESS = "ORIGINATION::CLEAR_PUT_BUSINESS";

export const INIT_POST_BUSINESS_ADDRESS = "ORIGINATION::INIT_POST_BUSINESS_ADDRESS";
export const RESPONSE_POST_BUSINESS_ADDRESS = "ORIGINATION::RESPONSE_POST_BUSINESS_ADDRESS";
export const ERROR_POST_BUSINESS_ADDRESS = "ORIGINATION::ERROR_POST_BUSINESS_ADDRESS";
export const CLEAN_POST_BUSINESS_ADDRESS = "ORIGINATION::CLEAN_POST_BUSINESS_ADDRESS";

export const INIT_PUT_ADDITIONAL_DATA = "ORIGINATION::INIT_PUT_ADDITIONAL_DATA";
export const RESPONSE_PUT_ADDITIONAL_DATA =
  "ORIGINATION::RESPONSE_PUT_ADDITIONAL_DATA";
export const ERROR_PUT_ADDITIONAL_DATA =
  "ORIGINATION::ERROR_PUT_ADDITIONAL_DATA";
export const CLEAR_PUT_ADDITIONAL_DATA =
  "ORIGINATION::CLEAR_PUT_ADDITIONAL_DATA";

export const INIT_CLIENT_NEW_PHONE_NUMBER =
  "ORIGINATION::INIT_CLIENT_NEW_PHONE_NUMBER";
export const RESPONSE_CLIENT_NEW_PHONE_NUMBER =
  "ORIGINATION::RESPONSE_CLIENT_NEW_PHONE_NUMBER";
export const ERROR_CLIENT_NEW_PHONE_NUMBER =
  "ORIGINATION::ERROR_CLIENT_NEW_PHONE_NUMBER";
export const CLEAR_CLIENT_NEW_PHONE_NUMBER =
  "ORIGINATION::CLEAR_CLIENT_NEW_PHONE_NUMBER";

export const INIT_SEND_NIP_PHONE = "ORIGINATION::INIT_SEND_NIP_PHONE";
export const RESPONSE_SEND_NIP_PHONE = "ORIGINATION::RESPONSE_SEND_NIP_PHONE";
export const ERROR_SEND_NIP_PHONE = "ORIGINATION::ERROR_SEND_NIP_PHONE";
export const CLEAR_SEND_NIP_PHONE = "ORIGINATION::CLEAR_SEND_NIP_PHONE";

export const INIT_VALIDATE_NIP_PHONE = "ORIGINATION::INIT_VALIDATE_NIP_PHONE";
export const RESPONSE_VALIDATE_NIP_PHONE =
  "ORIGINATION::RESPONSE_VALIDATE_NIP_PHONE";
export const ERROR_VALIDATE_NIP_PHONE = "ORIGINATION::ERROR_VALIDATE_NIP_PHONE";
export const CLEAR_VALIDATE_NIP_PHONE = "ORIGINATION::CLEAR_VALIDATE_NIP_PHONE";

export const CLEAR_ERRORS = "ORIGINATION::CLEAR_ERRORS";
export const INIT_POST_REVERSE_OTP = "ORIGINATION::INIT_POST_REVERSE_OTP";
export const RESPONSE_POST_REVERSE_OTP = "ORIGINATION::RESPONSE_POST_REVERSE_OTP";
export const ERROR_POST_REVERSE_OTP = "ORIGINATION::ERROR_POST_REVERSE_OTP";
export const INIT_VALIDATE_REVERSE_OTP = "ORIGINATION::INIT_VALIDATE_REVERSE_OTP";
export const RESPONSE_VALIDATE_REVERSE_OTP = "ORIGINATION::RESPONSE_VALIDATE_REVERSE_OTP";
export const ERROR_VALIDATE_REVERSE_OTP = "ORIGINATION::ERROR_VALIDATE_REVERSE_OTP";
