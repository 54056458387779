import React from "react";
import PropTypes from "prop-types";

import SignatureCanvas from "react-signature-canvas";

import { BoxLayout, Button, Text, Modal, CheckBox } from "../../components";
import Images from "../../assets/images";
export function Singning({
  classes,
  resetSing,
  saveSing,
  sing,
  isVisible,
  setIsVisible,
  accept,
  handleChangeCheckbox,
  isError,
  setIsError,
  onClickWhatsapp
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <BoxLayout className={classes.singContainer}>
          <SignatureCanvas
            ref={sing}
            penColor="#000000"
            canvasProps={{
              width: "300px",
              height: "470px",
              className: classes.sing,
            }}
            backgroundColor="rgba(246, 249, 252, 0.5)"
            minWidth={3}
          />
          <Text
            className={classes.textSing}
            variant="h3"
            color="initial"
            aling="center"
          >
            Firma dentro del recuadro con tu dedo
          </Text>
          <Button
            newCustomStyle={styles.resetButton}
            variant="text"
            color="primary"
            onClick={resetSing}
          >
            Reintentar firma
          </Button>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="primary"
            onClick={() => setIsVisible(true)}
          >
            Guardar firma
          </Button>
        </BoxLayout>
        <Modal
          open={isVisible | isError}
          onClose={isError === false ? () => setIsVisible(false): () => setIsError(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalSingning}
        >
          {isError === false ? 
            <BoxLayout className={classes.modalContainer}>
              <Text
                className={classes.titleModal}
                variant="h5"
                color="initial"
                aling="justify"
              >
                Aceptación de terminos y condiciones
              </Text>
              <div className={classes.checkContainer}>
                <CheckBox checked={accept} onChange={handleChangeCheckbox} />
                <Text
                  className={classes.textCheck}
                  variant="h4"
                  color="initial"
                  aling="justify"
                >
                  Entiendo y acepto los términos del contrato
                </Text>
              </div>
              <Button
                className={classes.buttonModal}
                variant="contained"
                color="primary"
                onClick={saveSing}
                isDisabled={accept === false}
              >
                Continuar
              </Button>
            </BoxLayout>:
            <BoxLayout className={classes.modalErrorContainer}>
              <Text
                className={classes.titleErrorModal}
                variant="h5"
                color="initial"
                aling="justify"
              >
                ¡Ya has firmado el contrato exitosamente!
              </Text>
              <Text
                className={classes.textError}
                variant="h1"
                color="initial"
                aling="justify"
              >
                Si crees que se trata de un error contacta a soporte.
              </Text>
              <button
                onClick={() => onClickWhatsapp()}
                className={classes.itemContainerWhatsApp}
              >
                <img className={classes.image} src={Images.whatsappWhite} />
                <Text variant="h7" color="initial" align="justify" className={classes.textWhats}>
                  Contactar a soporte
                </Text>
              </button>
            </BoxLayout>
          }
        </Modal>
      </BoxLayout>
    </BoxLayout>
  );
}

export default Singning;

const styles = {
  resetButton: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
    border: "1px solid #3050ff",
    paddingLeft: 60,
    paddingRight: 60,
    marginTop: 20,
  },
};

Singning.propTypes = {
  classes: PropTypes.object.isRequired,
  resetSing: PropTypes.func,
  saveSing: PropTypes.func,
  sing: PropTypes.func,
  accept: PropTypes.bool,
  setAccept: PropTypes.func,
};
