import React from "react";
import { useSelector } from "react-redux";

import CongratulationsPartnerUI from "./CongratulationsPartner";
import useStyles from "./CongratulationsPartner.styles";

export function CongratulationsPartner() {
  const { collateralName, shopkeeperName } = useSelector(
    (state) => state.collateralReducer
  );
  const classes = useStyles();

  const onContinueAction = () => {
    window.location.href = "https://www.yofio.co/";
  };

  return (
    <CongratulationsPartnerUI
      classes={classes}
      _onContinueAction={onContinueAction}
      collateralName={collateralName}
      shopkeeperName={shopkeeperName}
    />
  );
}

export default CongratulationsPartner;
