import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ContainerGeneral
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "640px",
    backgroundColor: "#FFFFFF",
  },
  // UnitComponent
  processContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorVerification: {
    marginTop: 150,
  },
  title: {
    fontSize: 18,
    fontFamily: "Poppins",
    marginTop: 25,
    textAlign: "center",
  },
  text: {
    textAlign: "center",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    fontFamily: "Poppins",
    fontSize: 14,
  },
}));

export default useStyles;
