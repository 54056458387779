import React from "react";

import RejectedUI from "./Rejected";
import useStyles from "./Rejected.styles";

export function Rejected(props) {
  const classes = useStyles();

  const _onContinueAction = () => {
    window.open(process.env.REACT_APP_WHATSAPP_LINK);
  };

  return <RejectedUI classes={classes} _onContinueAction={_onContinueAction} />;
}

export default Rejected;
