export const INIT_GET_CITIES = "CATALOGS::INIT_GET_CITIES";
export const RESPONSE_GET_CITIES = "CATALOGS::RESPONSE_GET_CITIES";
export const ERROR_GET_CITIES = "CATALOGS::ERROR_GET_CITIES";

export const INIT_GET_BUSSINESS_TYPE = "CATALOGS::INIT_GET_BUSSINESS_TYPE";
export const RESPONSE_GET_BUSSINESS_TYPE =
  "CATALOGS::RESPONSE_GET_BUSSINESS_TYPE";
export const ERROR_GET_BUSSINESS_TYPE = "CATALOGS::ERROR_GET_BUSSINESS_TYPE";

export const INIT_GET_ZIP_CODE = "CATALOGS::INIT_GET_ZIP_CODE";
export const RESPONSE_GET_ZIP_CODE = "CATALOGS::RESPONSE_GET_ZIP_CODE";
export const ERROR_GET_ZIP_CODE = "CATALOGS::ERROR_GET_ZIP_CODE";
export const CLEAR_GET_ZIP_CODE = "CATALOGS::CLEAR_GET_ZIP_CODE";

export const INIT_GET_SALES_RANGE = "CATALOGS::INIT_GET_SALES_RANGE";
export const RESPONSE_GET_SALES_RANGE = "CATALOGS::RESPONSE_GET_SALES_RANGE";
export const ERROR_GET_SALES_RANGE = "CATALOGS::ERROR_GET_SALES_RANGE";

export const CLEAR_ERRORS = "CATALOGS::CLEAR_ERRORS";
