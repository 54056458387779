import * as ACTION from "../actionTypes/contract";

const contractState = {
  loading: {
    USER_CONTRACT: false,
    USER_CONTRACT_SIGNATURE: false,
    USER_MATI: false,
  },
  error: {
    USER_CONTRACT: null,
    USER_CONTRACT_SIGNATURE: null,
    USER_MATI: null,
  },

  userContract: {},
  userContractSignature: {},
  userMati: {},
};

const contract = (state = contractState, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT: true },
        error: { ...state.error, USER_CONTRACT: null },
      };
    case ACTION.RESPONSE_GET_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT: false },
        error: { ...state.error, USER_CONTRACT: null },
        userContract: action.result,
      };
    case ACTION.ERROR_GET_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT: false },
        error: { ...state.error, USER_CONTRACT: action.error },
      };
    case ACTION.CLEAR_GET_USER_CONTRACT:
      return { ...state, userContract: {} };

    case ACTION.INIT_PUT_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT_SIGNATURE: true },
        error: { ...state.error, USER_CONTRACT_SIGNATURE: null },
      };
    case ACTION.RESPONSE_PUT_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT_SIGNATURE: false },
        error: { ...state.error, USER_CONTRACT_SIGNATURE: null },
        userContractSignature: action.result,
      };
    case ACTION.ERROR_PUT_USER_CONTRACT:
      return {
        ...state,
        loading: { ...state.loading, USER_CONTRACT_SIGNATURE: false },
        error: { ...state.error, USER_CONTRACT_SIGNATURE: action.error },
      };
    case ACTION.CLEAR_PUT_USER_CONTRACT:
      return { ...state, userContractSignature: {} };

    case ACTION.INIT_GET_USER_MATI:
      return {
        ...state,
        loading: { ...state.loading, USER_MATI: true },
        error: { ...state.error, USER_MATI: null },
      };
    case ACTION.RESPONSE_GET_USER_MATI:
      return {
        ...state,
        loading: { ...state.loading, USER_MATI: false },
        error: { ...state.error, USER_MATI: null },
        userMati: action.result,
      };
    case ACTION.ERROR_GET_USER_MATI:
      return {
        ...state,
        loading: { ...state.loading, USER_MATI: false },
        error: { ...state.error, USER_MATI: action.error },
      };
    case ACTION.CLEAR_GET_USER_MATI:
      return { ...state, userMati: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: contractState.error };
    default:
      return state;
  }
};

export default contract;
