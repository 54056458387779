import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import WaitingUI from "./Waiting";
import useStyles from "./Waiting.styles";
import { getUserDataDetail } from "../../redux/actions/origination.action";

export function Waiting(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();
  const { user_id } = useParams();
  const [timer, setTimer] = useState(12);

  useEffect(() => {
    setTimeout(() => {
      if (timer != 0) {
        dispatch(getUserDataDetail(user_id, "SK"));
      } else {
        history("/something-happened", { replace: true });
      }
      setTimer((prevTimer) => prevTimer - 1);
    }, 5000);
  }, [timer]);

  return <WaitingUI classes={classes} />;
}

export default Waiting;
