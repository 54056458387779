import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Text, Button, Modal } from "../../components";
import images from "../../assets/images";
export function ErrorDocuments({ 
  classes, 
  reload,
  attempts,
  isVisible,
  setIsVisible
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.processContainer}>
          <img
            src={images.error}
            className={classes.errorDocuments}
            alt="img-error"
          />
          <Text
            className={classes.title}
            variant="h5"
            color="initial"
            aling="center"
          >
            ¡Sucedió algo!
          </Text>
          <Text
            className={classes.text}
            variant="h4"
            color="initial"
            aling="center"
          >
            Tus documentos no pudieron ser validados.
          </Text>
          <div className={classes.instructions}>
            <Text
              className={classes.information}
              variant="h4"
              color="initial"
              aling="left"
            >
              - Recuerda que tu selfie debe coincidir con tu identificación.
            </Text>
            <Text
              className={classes.information}
              variant="h4"
              color="initial"
              aling="left"
            >
              - No tomes fotos a fotografías.
            </Text>
            <Text
              className={classes.information}
              variant="h4"
              color="initial"
              aling="left"
            >
              - Toma tu foto en un lugar bien iluminado.
            </Text>
            <Text
              className={classes.information}
              variant="h4"
              color="initial"
              aling="left"
            >
              - La foto de tu identificación debe ser por frente y vuelta.
            </Text>
          </div>
        </div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={attempts !== null && attempts <= 0 ? () => setIsVisible(true) : () => reload()}
        >
          Reiniciar evaluación
        </Button>
        <Modal
          open={isVisible}
          className={classes.modal}
        >
          <BoxLayout className={classes.modalContainer}>
            <Text
              className={classes.titleEndorsement}
              variant="h2"
              color="initial"
              align="center"
            >
              Intentos no disponibles
            </Text>
            <Text
              className={classes.textEndorsement}
              variant="h3"
              color="initial"
              aling="center"
            >
              ¡Agotaste los intentos de verificación!
            </Text>
              <button
                onClick={() =>( window.location.href = `${process.env.REACT_APP_WHATSAPP_LINK}&text=Mis%20documentos%20no%20pudieron%20ser%20validados`)}
                className={classes.itemContainerWhatsApp}
              >
                <img className={classes.image} src={images.whatsappWhite} />
                <Text variant="h7" color="initial" align="justify" className={classes.textWhats}>
                  Contactar a soporte
                </Text>
              </button>
          </BoxLayout>
        </Modal>
      </BoxLayout>
    </BoxLayout>
  );
}

export default ErrorDocuments;

ErrorDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};
