/* eslint-disable indent */
import * as ACTION from "../actionTypes/origination";

const dataState = {
  loading: {
    GET_USER_DATA: false,
    GET_USER_DATA_DETAIL: false,
    POST_USER: false,
    GET_NIP_DATA: false,
    GET_RESEND_NIP_DATA: false,
    CONFIRM_NIP: false,
    CREATE_PWD: false,
    POST_ADDRESS: false,
    PUT_BUSINESS: false,
    POST_BUSINESS_ADDRESS: false,
    PUT_ADDITIONAL_DATA: false,
    PUT_CLIENT_NEW_PHONE: false,
    SEND_NIP_PHONE: false,
    VALIDATE_NIP_PHONE: false,
  },
  error: {
    GET_USER_DATA: null,
    GET_USER_DATA_DETAIL: null,
    POST_USER: null,
    GET_NIP_DATA: null,
    GET_RESEND_NIP_DATA: false,
    CONFIRM_NIP: null,
    CREATE_PWD: null,
    POST_ADDRESS: null,
    PUT_BUSINESS: null,
    POST_BUSINESS_ADDRESS: null,
    PUT_ADDITIONAL_DATA: null,
    PUT_CLIENT_NEW_PHONE: null,
    SEND_NIP_PHONE: null,
    VALIDATE_NIP_PHONE: null,
  },

  userData: {},
  userDataDetail: {},
  postUserData: {},
  postAddress: {},
  putBusiness: {},
  postBusinessAddress: {},
  authData: {},
  putAdditionalData: {},
  putClientNewPhone: {},

  defaultNip: "",
  defaultResendNip: "",
  nipConfirmed: false,
  pwdConfirmed: false,
  codeConfirmed: false,
  newUser: undefined,
  redirectionUrlObject: {},

  sendNipPhone: {},
  validateNipPhone: {},

  isLoadingKibanShopkeeper: false,
};

const origination = (state = dataState, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_USER_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA: true },
        error: { ...state.error, GET_USER_DATA: null },
      };
    case ACTION.RESPONSE_GET_USER_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA: false },
        error: { ...state.error, GET_USER_DATA: null },
        userData: action.result,
        newUser: false,
      };
    case ACTION.ERROR_GET_USER_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA: false },
        error: { ...state.error, GET_USER_DATA: action.error },
        newUser: undefined,
      };
    case ACTION.NEW_USER:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA: false },
        userData: {},
        newUser: true,
      };
    case ACTION.CLEAR_GET_USER_DATA:
      return { ...state, userData: {}, newUser: undefined };

    case ACTION.INIT_GET_USER_DATA_DETAIL:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA_DETAIL: true },
        error: { ...state.error, GET_USER_DATA_DETAIL: null },
      };
    case ACTION.RESPONSE_GET_USER_DATA_DETAIL:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA_DETAIL: false },
        error: { ...state.error, GET_USER_DATA_DETAIL: null },
        userDataDetail: action.result,
      };
    case ACTION.ERROR_GET_USER_DATA_DETAIL:
      return {
        ...state,
        loading: { ...state.loading, GET_USER_DATA_DETAIL: false },
        error: { ...state.error, GET_USER_DATA_DETAIL: action.error },
      };
    case ACTION.CLEAR_GET_USER_DATA_DETAIL:
      return { ...state, userDataDetail: {} };

    case ACTION.INIT_POST_USER:
      return {
        ...state,
        loading: { ...state.loading, POST_USER: true },
        error: { ...state.error, POST_USER: null },
      };
    case ACTION.RESPONSE_POST_USER:
      return {
        ...state,
        loading: { ...state.loading, POST_USER: false },
        error: { ...state.error, POST_USER: null },
        postUserData: action.result,
      };
    case ACTION.ERROR_POST_USER:
      return {
        ...state,
        loading: { ...state.loading, POST_USER: false },
        error: { ...state.error, POST_USER: action.error },
      };
    case ACTION.CLEAR_POST_USER:
      return { ...state, postUserData: {} };

    case ACTION.INIT_SEND_CODE:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: true },
        error: { ...state.error, GET_NIP_DATA: null },
      };
    case ACTION.RESPONSE_CODE_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: false },
        error: { ...state.error, GET_NIP_DATA: null },
      };
    case ACTION.ERROR_CODE_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: false },
        error: {
          ...state.error,
          GET_NIP_DATA: action.readableMessage || action.error,
        },
      };

    case ACTION.INIT_CODE_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: true },
        error: { ...state.error, CONFIRM_NIP: null },
      };
    case ACTION.RESPONSE_CODE_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: null },
        codeConfirmed: true,
        authData: action.result,
      };
    case ACTION.ERROR_CODE_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: action.error },
        codeConfirmed: false,
        authData: {},
      };

    case ACTION.INIT_SEND_NIP:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: true },
        error: { ...state.error, GET_NIP_DATA: null },
      };
    case ACTION.RESPONSE_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: false },
        error: { ...state.error, GET_NIP_DATA: null },
        defaultNip: action.result.nip,
        collateralName: action.result.collateralName,
        shopkeeperName: action.result.shopkeeperName,
      };
    case ACTION.ERROR_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: false },
        error: {
          ...state.error,
          GET_NIP_DATA: action.readableMessage || action.error,
        },
        defaultNip: "",
        collateralName: "",
        shopkeeperName: "",
      };
    case ACTION.CLEAR_SEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_NIP_DATA: false },
        error: { ...state.error, GET_NIP_DATA: null },
        defaultNip: "",
        collateralName: "",
        shopkeeperName: "",
      };

    case ACTION.INIT_RESEND_NIP:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: true },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
      };
    case ACTION.RESPONSE_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
        defaultResendNip: action.result.nip,
        collateralName: action.result.collateralName,
        shopkeeperName: action.result.shopkeeperName,
      };
    case ACTION.ERROR_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: {
          ...state.error,
          GET_RESEND_NIP_DATA: action.readableMessage || action.error,
        },
        defaultResendNip: "",
        collateralName: "",
        shopkeeperName: "",
      };
    case ACTION.CLEAR_RESEND_NIP_DATA:
      return {
        ...state,
        loading: { ...state.loading, GET_RESEND_NIP_DATA: false },
        error: { ...state.error, GET_RESEND_NIP_DATA: null },
        defaultResendNip: "",
        collateralName: "",
        shopkeeperName: "",
      };

    case ACTION.INIT_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: true },
        error: { ...state.error, CONFIRM_NIP: null },
      };
    case ACTION.RESPONSE_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: null },
        nipConfirmed: true,
      };
    case ACTION.ERROR_NIP_CONFIRMATION:
      return {
        ...state,
        loading: { ...state.loading, CONFIRM_NIP: false },
        error: { ...state.error, CONFIRM_NIP: action.error },
        nipConfirmed: false,
      };

    case ACTION.INIT_PWD_CREATION:
      return {
        ...state,
        loading: { ...state.loading, CREATE_PWD: true },
        error: { ...state.error, CREATE_PWD: null },
      };
    case ACTION.RESPONSE_PWD_CREATION:
      return {
        ...state,
        loading: { ...state.loading, CREATE_PWD: false },
        error: { ...state.error, CREATE_PWD: null },
        pwdConfirmed: true,
      };
    case ACTION.ERROR_PWD_CREATION:
      return {
        ...state,
        loading: { ...state.loading, CREATE_PWD: false },
        error: { ...state.error, CREATE_PWD: action.error },
        pwdConfirmed: false,
      };

    case ACTION.INIT_POST_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_ADDRESS: true },
        error: { ...state.error, POST_ADDRESS: null },
      };
    case ACTION.RESPONSE_POST_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_ADDRESS: false },
        error: { ...state.error, POST_ADDRESS: null },
        postAddress: action.result,
      };
    case ACTION.ERROR_POST_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_ADDRESS: false },
        error: { ...state.error, POST_ADDRESS: action.error },
      };
    case ACTION.CLEAR_POST_ADDRESS:
      return { ...state, postAddress: {} };

    case ACTION.INIT_PUT_BUSINESS:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUSINESS: true },
        error: { ...state.error, PUT_BUSINESS: null },
      };
    case ACTION.RESPONSE_PUT_BUSINESS:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUSINESS: false },
        error: { ...state.error, PUT_BUSINESS: null },
        putBusiness: action.result,
      };
    case ACTION.ERROR_PUT_BUSINESS:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUSINESS: false },
        error: { ...state.error, PUT_BUSINESS: action.error },
      };
    case ACTION.CLEAR_PUT_BUSINESS:
      return { ...state, putBusiness: {} };

    case ACTION.INIT_POST_BUSINESS_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_BUSINESS_ADDRESS: true },
        error: { ...state.error, POST_BUSINESS_ADDRESS: null },
      };
    case ACTION.RESPONSE_POST_BUSINESS_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_BUSINESS_ADDRESS: false },
        error: { ...state.error, POST_BUSINESS_ADDRESS: null },
        postBusinessAddress: action.result,
      };
    case ACTION.ERROR_POST_BUSINESS_ADDRESS:
      return {
        ...state,
        loading: { ...state.loading, POST_BUSINESS_ADDRESS: false },
        error: { ...state.error, POST_BUSINESS_ADDRESS: action.error },
      };
    case ACTION.CLEAN_POST_BUSINESS_ADDRESS:
      return { ...state, postBusinessAddress: {} };

    case ACTION.INIT_PUT_ADDITIONAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, PUT_ADDITIONAL_DATA: true },
        error: { ...state.error, PUT_ADDITIONAL_DATA: null },
      };
    case ACTION.RESPONSE_PUT_ADDITIONAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, PUT_ADDITIONAL_DATA: false },
        error: { ...state.error, PUT_ADDITIONAL_DATA: null },
        putAdditionalData: action.result,
      };
    case ACTION.ERROR_PUT_ADDITIONAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, PUT_ADDITIONAL_DATA: false },
        error: { ...state.error, PUT_ADDITIONAL_DATA: action.error },
      };
    case ACTION.CLEAR_PUT_ADDITIONAL_DATA:
      return { ...state, putAdditionalData: {} };

    case ACTION.INIT_CLIENT_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_CLIENT_NEW_PHONE: true },
        error: { ...state.error, PUT_CLIENT_NEW_PHONE: null },
      };
    case ACTION.RESPONSE_CLIENT_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_CLIENT_NEW_PHONE: false },
        error: { ...state.error, PUT_CLIENT_NEW_PHONE: null },
        putClientNewPhone: action.result,
      };
    case ACTION.ERROR_CLIENT_NEW_PHONE_NUMBER:
      return {
        ...state,
        loading: { ...state.loading, PUT_CLIENT_NEW_PHONE: false },
        error: { ...state.error, PUT_CLIENT_NEW_PHONE: action.error },
      };
    case ACTION.CLEAR_CLIENT_NEW_PHONE_NUMBER:
      return { ...state, putClientNewPhone: {} };

    case ACTION.INIT_SEND_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, SEND_NIP_PHONE: true },
        error: { ...state.error, SEND_NIP_PHONE: null },
      };
    case ACTION.RESPONSE_SEND_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, SEND_NIP_PHONE: false },
        error: { ...state.error, SEND_NIP_PHONE: null },
        sendNipPhone: action.result,
      };
    case ACTION.ERROR_SEND_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, SEND_NIP_PHONE: false },
        error: { ...state.error, SEND_NIP_PHONE: action.error },
      };
    case ACTION.CLEAR_SEND_NIP_PHONE:
      return { ...state, sendNipPhone: {} };

    case ACTION.INIT_VALIDATE_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, VALIDATE_NIP_PHONE: true },
        error: { ...state.error, VALIDATE_NIP_PHONE: null },
      };
    case ACTION.RESPONSE_VALIDATE_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, VALIDATE_NIP_PHONE: false },
        error: { ...state.error, VALIDATE_NIP_PHONE: null },
        validateNipPhone: action.result,
      };
    case ACTION.ERROR_VALIDATE_NIP_PHONE:
      return {
        ...state,
        loading: { ...state.loading, VALIDATE_NIP_PHONE: false },
        error: { ...state.error, VALIDATE_NIP_PHONE: action.error },
      };
    case ACTION.CLEAR_VALIDATE_NIP_PHONE:
      return { ...state, validateNipPhone: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataState.error };

    case ACTION.INIT_POST_REVERSE_OTP:
      return {
        ...state,
        loading: true
      };
    case ACTION.RESPONSE_POST_REVERSE_OTP:
      return {
        ...state,
        loading: false,
        redirectionUrlObject: action.result
      };
    case ACTION.ERROR_POST_REVERSE_OTP:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    case ACTION.INIT_VALIDATE_REVERSE_OTP:
      return {
        ...state,
        loading: true
      };
    case ACTION.RESPONSE_VALIDATE_REVERSE_OTP:
      return {
        ...state,
        loading: false,
        validateNipPhone: action.result
      };
    case ACTION.ERROR_VALIDATE_REVERSE_OTP:
      return {
        ...state,
        loading: false,
        error: action.result
      };

    default:
      return state;
  }
};

export default origination;
