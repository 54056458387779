import React from "react";
import PropTypes from "prop-types";

import { Box, capitalize } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Done, Close } from "@material-ui/icons";

import { BoxLayout, Button, Text, TextInput, MenuItem } from "../../components";
import { format } from "date-fns";
import Stepper from "../../components/Stepper";

export function AboutYouForm({
  classes,
  handleChangeInput,
  handleTrim,
  handleSubmit,
  values,
  errorMessages,
  setDate,
}) {
  const days = [...Array(31).keys()].map((day) => day + 1);
  const days2 = [...Array(30).keys()].map((day) => day + 1);
  const febdays = [...Array(29).keys()].map((day) => day + 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const years = [...Array(82).keys()].map((year) => new Date().getFullYear() - 18 - year);
  setDate(
    format(new Date(values.year, values.month - 1, values.day), "yyyy-MM-dd")
  );
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
      <Stepper activeStep={1} activeBars={1}/>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          Sobre ti
        </Text>
        <BoxLayout className={classes.formContainer}>
          <TextInput
            className={classes.formElement}
            label="Nombre(s)"
            variant="standard"
            value={capitalize(values.names)}
            onChange={handleChangeInput("names")}
            onBlur={handleTrim("names")}
            inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
            helperText={errorMessages.names}
            error={errorMessages.names !== ""}
            focused={
              values.names.length >= 3 && errorMessages.names === ""
                ? true
                : false
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {values.names.length >= 3 && errorMessages.names === "" && (
                    <Done style={{ color: "#009805" }} />
                  )}
                  {values.names.length >= 3 && errorMessages.names !== "" && (
                    <Close style={{ color: "#ff664d" }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            className={classes.formElement}
            label="Apellido Paterno"
            variant="standard"
            value={capitalize(values.first_lastname)}
            onChange={handleChangeInput("first_lastname")}
            onBlur={handleTrim("first_lastname")}
            inputProps={{ maxLength: 25 }}
            helperText={errorMessages.first_lastname}
            error={errorMessages.first_lastname !== ""}
            focused={
              values.first_lastname.length >= 3 &&
                errorMessages.first_lastname === ""
                ? true
                : false
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {values.first_lastname.length >= 3 &&
                    errorMessages.first_lastname === "" && (
                      <Done style={{ color: "#009805" }} />
                    )}
                  {values.first_lastname.length >= 3 &&
                    errorMessages.first_lastname !== "" && (
                      <Close style={{ color: "#ff664d" }} />
                    )}
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            className={classes.formElement}
            label="Apellido Materno"
            variant="standard"
            value={capitalize(values.second_lastname)}
            onChange={handleChangeInput("second_lastname")}
            onBlur={handleTrim("second_lastname")}
            inputProps={{ maxLength: 25 }}
            helperText={errorMessages.second_lastname}
            error={errorMessages.second_lastname !== ""}
            focused={
              values.second_lastname.length >= 3 &&
                errorMessages.second_lastname === ""
                ? true
                : false
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {values.second_lastname.length >= 3 &&
                    errorMessages.second_lastname === "" && (
                      <Done style={{ color: "#009805" }} />
                    )}
                  {values.second_lastname.length >= 3 &&
                    errorMessages.second_lastname !== "" && (
                      <Close style={{ color: "#ff664d" }} />
                    )}
                </InputAdornment>
              ),
            }}
          />
          <Box color="#757575">
            <Text>Fecha de nacimiento</Text>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            marginTop={2}
          >
            <Box marginRight={2}>
              <TextInput
                className={classes.bdDay}
                label="Día"
                variant="standard"
                value={values.day}
                onChange={handleChangeInput("day")}
                inputProps={{ max: 31 }}
                helperText={errorMessages.day}
                error={errorMessages.day !== ""}
                focused={values.day !== "" ? true : false}
                select
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.selectAdornment}>
                      {values.day !== "" && (
                        <Done
                          style={{
                            color: "#009805",
                            position: 'relative',
                            right: 20,
                            zIndex: 1,
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {values.month === 2
                  ? febdays.map((day, index) => (
                    <MenuItem value={day}>{day}</MenuItem>
                  ))
                  : values.month === 1 ||
                    values.month === 3 ||
                    values.month === 5 ||
                    values.month === 7 ||
                    values.month === 8 ||
                    values.month === 10 ||
                    values.month === 12
                    ? days.map((day, index) => (
                      <MenuItem value={day}>{day}</MenuItem>
                    ))
                    : days2.map((day, index) => (
                      <MenuItem value={day}>{day}</MenuItem>
                    ))}
              </TextInput>
            </Box>
            <Box marginRight={2}>
              <TextInput
                className={classes.bdMonth}
                label="Mes"
                variant="standard"
                value={values.month}
                onChange={handleChangeInput("month")}
                inputProps={{ max: 12 }}
                helperText={errorMessages.month}
                error={errorMessages.month !== ""}
                focused={values.month !== "" ? true : false}
                select
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.selectAdornment}>
                      {values.month !== "" && (
                        <Done
                          style={{
                            color: "#009805",
                            position: 'relative',
                            right: 20,
                            zIndex: 1,
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {months.map((month, index) => (
                  <MenuItem value={index + 1}>{month}</MenuItem>
                ))}
              </TextInput>
            </Box>
            <Box marginRight={values.year !== "" ? 12 : 0}>
              <TextInput
                className={classes.bdYear}
                label="Año"
                variant="standard"
                value={values.year}
                onChange={handleChangeInput("year")}
                inputProps={{ max: 1923 }}
                helperText={errorMessages.year}
                error={errorMessages.year !== ""}
                focused={values.year !== "" ? true : false}
                select
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.selectAdornment}>
                      {values.year !== "" && (
                        <Done style={{
                          color: "#009805",
                          position: 'relative',
                          right: 20,
                          zIndex: 1,
                        }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {years.map((year, index) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </TextInput>
            </Box>
          </Box>
          <TextInput
            className={classes.formElement}
            label="Tiempo viviendo en tu domicilio actual"
            variant="standard"
            select
            value={values.time_here}
            onChange={handleChangeInput("time_here")}
            helperText={errorMessages.time_here}
            error={errorMessages.time_here !== ""}
            focused={values.time_here !== "" ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.selectAdornment}>
                  {values.time_here !== "" && (
                    <Done style={{
                      color: "#009805",
                      position: 'relative',
                      right: 20,
                      zIndex: 1,
                    }} />
                  )}
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="1">1 año</MenuItem>
            <MenuItem value="2">2 años</MenuItem>
            <MenuItem value="3">3 años</MenuItem>
            <MenuItem value="4">4 años o más</MenuItem>
          </TextInput>
          <TextInput
            className={classes.formElement}
            label="Estado civil"
            variant="standard"
            select
            value={values.civil_status}
            onChange={handleChangeInput("civil_status")}
            helperText={errorMessages.civil_status}
            error={errorMessages.civil_status !== ""}
            focused={values.civil_status !== "" ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.selectAdornment}>
                  {values.civil_status !== "" && (
                    <Done style={{
                      color: "#009805",
                      position: 'relative',
                      right: 20,
                      zIndex: 1,
                    }} />
                  )}
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="SINGLE">Solter@</MenuItem>
            <MenuItem value="MARRIED">Casad@</MenuItem>
            <MenuItem value="FREE_UNION">Unión libre</MenuItem>
            <MenuItem value="DIVORCED">Separad@ / Divorciad@</MenuItem>
            <MenuItem value="WIDOW">Viud@</MenuItem>
          </TextInput>
          <Button
            newCustomStyle={styles.newStyleButton}
            variant="contained"
            color="primary"
            isDisabled={
              !(
                values.names !== "" &&
                values.first_lastname !== "" &&
                values.second_lastname !== "" &&
                values.year !== "" &&
                values.month !== "" &&
                values.day !== "" &&
                values.time_here !== "" &&
                values.civil_status !== ""
              )
            }
            onClick={() => {
              handleSubmit();
            }}
          >
            Continuar
          </Button>
        </BoxLayout>
      </BoxLayout>
    </BoxLayout>
  );
}

export default AboutYouForm;

const styles = {
  newStyleButton: {
    marginTop: 30,
    marginBottom: 20,
  },
};
AboutYouForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeCheckbox: PropTypes.func,
  handleChangeInput: PropTypes.func,
  handleTrim: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
};
