import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  whatsAppContainer: {
    display: "flex",
    width: "30px",
    padding: "10px",
    backgroundColor: "#25D366",
    borderRadius: "50px",
    position: "sticky",
    bottom: 20,
    left: "90%",
  },
  whatsAppIcon: {
    width: "30px",
    height: "30px",
    color: "#FFFFFF",
  },
}));

export default useStyles;
