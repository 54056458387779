import React from "react";
import { useNavigate } from "react-router";

import NotFoundPageUI from "./NotFoundPage";
import useStyles from "./NotFoundPage.style";

function NotFoundPage() {
  const classes = useStyles();
  const handleBack = () => {
    const history = useNavigate();
    history(-1);
  };

  return <NotFoundPageUI handleBack={() => handleBack()} classes={classes} />;
}

export default NotFoundPage;

NotFoundPage.propTypes = {
};
