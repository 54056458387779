import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Button, Text } from "../../components";
import Images from "../../assets/images";

export function Rejected({ classes, _onContinueAction }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.imagesContainer}>
          <img src={Images.reject} alt="img-background" />
        </div>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          ¡Lo sentimos!
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          En este momento tu negocio no cumple los requisitos que se necesitan
          para otorgarte una línea de crédito
        </Text>
        <Text
          className={classes.subTitleSection}
          variant="h4"
          color="initial"
          align="justify"
        >
          Contacta a soporte para conocer más
        </Text>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          onClick={() => _onContinueAction()}
        >
          Contactar a soporte
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default Rejected;

Rejected.propTypes = {
  classes: PropTypes.object.isRequired,
  _onContinueAction: PropTypes.func,
};
