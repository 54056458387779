export default {
  api: {
    basePath: `${process.env.REACT_APP_REMOTE_SERVER}`,
    operationsPath: `${process.env.REACT_APP_OPERATIONS_SERVER}`,
  },
  registers: {
    route: "/admin/users/account",
  },
  customers: {
    route: "/admin/customers",
  },
  collections: {
    route: "/debt-collection-action",
  },
  loan: {
    route: "/admin/shop-loan/credit-line",
  },
  advisor: {
    route: "/admin/sales-advisor",
  },
  shop: {
    route: "admin/shops",
  },
};
