import React from "react";
import PropTypes, { func } from "prop-types";

import {
  BoxLayout,
  Button,
  Text,
  CodeInput,
  SuccessMessage,
  Modal,
  MenuItem,
  TextInput,
} from "../../components";
import Images from "../../assets/images";

export function SendSMSPartner({
  classes,
  _handleChangeNip,
  errorNip,
  _onContinueNipAction,
  isValidateNip,
  collateralName,
  shopkeeperName,
  defaultNip,
  resendNip,
  showMessageSMS,
  onCloseShowMessageSMS,
  isVisible,
  setIsVisible,
  handleChangeInputLada,
  lada,
  newPhoneNumber,
  handleChangeNewPhone,
  _newNumber,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "100px",
          }}
        >
          <img
            className={classes.yofioImage}
            src={Images.yofio_black}
            alt="img-yofio"
          />
        </div>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          ¡Hola&nbsp;
          {collateralName}!
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Tu conocido&nbsp;
          {shopkeeperName}
          &nbsp;tiene una solicitud de crédito con YoFio y te ha seleccionado
          como aval. Para continuar con su solicitud por favor acepta la
          consulta crediticia a favor FRESH LENDING SAPI DE CV.
        </Text>
        <BoxLayout className={classes.bodyContainer}>
          <Text
            className={classes.labelNip}
            variant="h5"
            color="initial"
            align="justify"
          >
            NIP
          </Text>
          {defaultNip !== "" ? (
            <CodeInput
              initialValue={parseInt(defaultNip, 10)}
              length={4}
              onChange={_handleChangeNip}
              type="numeric"
              inputMode="number"
              style={{ display: "flex", justifyContent: "center" }}
              inputStyle={{
                borderColor: "#4a4b4b",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "25%",
                height: "40px",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              autoSelect
            />
          ) : (
            <CodeInput
              length={4}
              onChange={_handleChangeNip}
              type="numeric"
              inputMode="number"
              style={{ display: "flex", justifyContent: "center" }}
              inputStyle={{
                borderColor: "#4a4b4b",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "25%",
                height: "40px",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              autoSelect
            />
          )}
          {errorNip !== "" && (
            <Text
              className={classes.labelErrorNip}
              variant="h5"
              color="initial"
              align="justify"
            >
              {errorNip}
            </Text>
          )}
        </BoxLayout>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          isDisabled={!isValidateNip}
          onClick={() => _onContinueNipAction()}
        >
          Aceptar consulta
        </Button>
        <Button
          newCustomStyle={styles.buttonStyle}
          variant="contained"
          color="primary"
          onClick={() => setIsVisible(true)}
        >
          Cambiar número télefonico
        </Button>
        <Modal
          open={isVisible}
          onClose={() => setIsVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.changeNumberContainer}
        >
          <BoxLayout className={classes.modalContainer}>
            <Text
              className={classes.titleEndorsement}
              variant="h2"
              color="initial"
              align="justify"
            >
              Nuevo número de Aval
            </Text>
            <Text
              className={classes.textEndorsement}
              variant="h3"
              color="initial"
              aling="justify"
            >
              Escribe el nuevo número al que vamos a enviar el NIP de consulta
              crediticia
            </Text>
            <div className={classes.phoneContainer}>
              <TextInput
                variant="standard"
                select
                value={lada}
                onChange={handleChangeInputLada("lada")}
              >
                <MenuItem value="+52">+52</MenuItem>
              </TextInput>
              <TextInput
                className={classes.phoneInput}
                label="Teléfono celular"
                type="number"
                value={newPhoneNumber}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 10,
                }}
                variant="standard"
                onChange={handleChangeNewPhone}
              />
            </div>
            <Button
              className={classes.modalButton}
              variant="contained"
              color="primary"
              onClick={() => _newNumber()}
            >
              Cambiar número
            </Button>
          </BoxLayout>
        </Modal>
        <Button
          newCustomStyle={styles.resendNipButton}
          variant="text"
          color="primary"
          onClick={() => resendNip()}
        >
          Reenviar Código
        </Button>
      </BoxLayout>
      {showMessageSMS && (
        <SuccessMessage
          message="Se ha enviado el nip nuevamente"
          onClose={() => onCloseShowMessageSMS(false)}
        />
      )}
    </BoxLayout>
  );
}

export default SendSMSPartner;

const styles = {
  buttonStyle: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
    marginTop: 15,
  },
  resendNipButton: {
    backgroundColor: "#ffffff",
    color: "#3050ff",
    marginTop: 20,
  },
};

SendSMSPartner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  _handleChangeNip: PropTypes.func,
  errorNip: PropTypes.string,
  _onContinueNipAction: PropTypes.func,
  isValidateNip: PropTypes.bool,
  collateralName: PropTypes.string,
  shopkeeperName: PropTypes.string,
  defaultNip: PropTypes.string,
  resendNip: PropTypes.func,
  showMessageSMS: PropTypes.bool,
  onCloseShowMessageSMS: PropTypes.func,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  handleChangeInputLada: PropTypes.func,
  lada: PropTypes.string,
  newPhoneNumber: PropTypes.string,
  handleChangeNewPhone: PropTypes.func,
  _newNumber: PropTypes.func,
};

SendSMSPartner.defaultProps = {
  _handleChangeNip: () => {},
  errorNip: "",
  _onContinueNipAction: () => {},
  isValidateNip: false,
  collateralName: "",
  shopkeeperName: "",
  defaultNip: "",
  resendNip: () => {},
  showMessageSMS: false,
  onCloseShowMessageSMS: () => {},
  handleChangeInputLada: () => {},
  lada: "",
  newPhoneNumber: "",
  handleChangeNewPhone: () => {},
  _newNumber: () => {},
};
