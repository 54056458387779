import { createTheme } from "@material-ui/core/styles";

const typography = {
  useNextVariants: true,
  fontFamily: ["Poppins"].join(","),
  fontWeightMedium: 500,
  h1: {
    fontSize: "2.4rem",
    fontWeight: "bold",
    lineHeight: "42px",
  },
  h2: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    lineHeight: "30px",
  },
  h3: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    lineHeight: "18px",
  },
  h4: {
    fontSize: ".9rem",
    lineHeight: "16px",
  },
  h5: {
    fontSize: ".8rem",
    fontWeight: "bold",
    lineHeight: "13px",
  },
  h6: {
    fontSize: ".7rem",
    fontWeight: "bold",
    lineHeight: "11px",
  },
  body1: {
    fontSize: ".8rem",
    lineHeight: "13px",
  },
  body2: {
    fontSize: ".7rem",
    fontWeight: "bold",
    lineHeight: "11px",
  },
  overline: {
    fontSize: "11px",
    lineHeight: "13px",
  },
  button: {
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "center",
    fontWeight: "normal",
  },
};

export const lightTheme = createTheme({
  palette: {
    primary: {
      light: "#ff7e77",
      main: "#ea4b4b",
      dark: "#b10523",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#0253cc",
    },
  },
  typography,
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      light: "#ff7e77",
      main: "#ea4b4b",
      dark: "#b10523",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#0253cc",
    },
  },
  typography,
});
