import React from 'react';
import WelcomeVideoUI from './WelcomeVideo';
import useStyles from './WelcomeVideo.styles';

function WelcomeVideo() {
  const classes = useStyles();
  const phoneNumber = process.env.REACT_APP_PHONE_TO_CALL.replace(/^tel:\+52/, '');
  const redirectToPlayStore = () => {
    window.location.href = process.env.REACT_APP_PLAYSTORE_LINK;
    window.analytics.track('Clic a descargar app YoFio desde Landing');
  };
  return (
    <WelcomeVideoUI
      classes={classes}
      phoneNumber={phoneNumber}
      redirectToPlayStore={redirectToPlayStore}
    />
  );
}

export default WelcomeVideo;
