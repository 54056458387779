import React from "react";
import PropTypes from "prop-types";

import { BoxLayout, Button, Text } from "../../components";
import Images from "../../assets/images";

export function CongratulationsPartner({
  classes,
  _onContinueAction,
  collateralName,
  shopkeeperName,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <div className={classes.imagesContainer}>
          <img src={Images.success} alt="img-background" />
        </div>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          ¡Gracias por confirmar&nbsp;
          {collateralName}!
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Nuestro equipo validará tu información para continuar con la solicitud
          de&nbsp;
          {shopkeeperName}
          &nbsp;para el crédito YoFio
        </Text>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          onClick={() => _onContinueAction()}
        >
          Finalizar
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default CongratulationsPartner;

CongratulationsPartner.propTypes = {
  classes: PropTypes.object.isRequired,
  _onContinueAction: PropTypes.func,
  collateralName: PropTypes.string,
  shopkeeperName: PropTypes.string,
};
